import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { productAjaxDispatch } from '../../reducers/HomeReducer';
import { Link, useNavigate } from 'react-router-dom';
import { productAjaxDispatch } from '../../reducers/HomeReducer';

function SearchBar() {
	const [query, setQuery] = useState('');
	const [category, setCategory] = useState('Select Category');
	const { product, pc, productAjax, loginInfo } = useSelector(
		(state) => state.home
	);
	const [isResultsVisible, setIsResultsVisible] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const searchRef = useRef(null);
	console.log('productAjax', productAjax);
	const handleSearch = () => {
		if (query) {
			console.log(`Searching for: ${query} in category: ${category}`);
		}
	};
	const handleInputChange = (e) => {
		setQuery(e.target.value);
		if (e.target.value) {
			let form = {
				keywords: e.target.value,
				limits: 10,
			};
			dispatch(productAjaxDispatch(form));
			setIsResultsVisible(true);
		} else {
			setIsResultsVisible(false);
		}
	};
	useEffect(() => {
		function handleClickOutside(event) {
			if (searchRef.current && !searchRef.current.contains(event.target)) {
				setIsResultsVisible(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchRef]);

	return (
		<div>
			<div
				ref={searchRef}
				className='search-bar'
				style={{ position: 'relative', width: '700px' }}>
				<input
					type='text'
					placeholder='Search for Products...'
					value={query}
					onChange={handleInputChange}
				/>
				{isResultsVisible && productAjax?.result.length > 0 && (
					<div
						style={{
							position: 'absolute',
							top: '100%',
							left: 0,
							right: 0,
							backgroundColor: 'white',
							border: '1px solid #ddd',
							maxHeight: '200px',
							overflowY: 'auto',
							zIndex: 1,
						}}>
						{productAjax?.result.map((item, index) => (
							<Link
								to={
									loginInfo?.status === 'success' &&
									loginInfo?.result?.status === '1'
										? `/productdetails/?name=${item.name}&id=${item.id}`
										: ''
								}>
								<div
									key={index}
									style={{
										padding: '10px',
										borderBottom: '1px solid #eee',
										display: 'flex',
										cursor: 'pointer',
									}}>
									<img
										src={item.imgpath}
										alt={item.name}
										style={{ width: '80px', marginRight: '10px' }}
									/>
									<div>
										<strong>{item.name}</strong>
										<p>₹{item.price_per}</p>
									</div>
								</div>
							</Link>
						))}
					</div>
				)}
				{/* <select onChange={(e) => setCategory(e.target.value)}>
					{pc?.result?.map((i) => {
						return <option value={i.name}>{i.name}</option>;
					})}
				</select> */}
				{/* <button onClick={handleSearch}>Search</button> */}
			</div>
		</div>
	);
}

export default SearchBar;
