import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import bag from '../../assets/images/bag.jpg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

const PopularCategories = () => {
	const { category, loginInfo } = useSelector((state) => state.home);
	const navigate = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const categories = [
		{ img: 'assets/imgs/shop/category-thumb-1.jpg', name: 'T-Shirt' },
		{ img: 'assets/imgs/shop/category-thumb-2.jpg', name: 'Bags' },
		{ img: 'assets/imgs/shop/category-thumb-3.jpg', name: 'Sandan' },
		{ img: 'assets/imgs/shop/category-thumb-4.jpg', name: 'Scarf Cap' },
		{ img: 'assets/imgs/shop/category-thumb-5.jpg', name: 'Shoes' },
		{ img: 'assets/imgs/shop/category-thumb-6.jpg', name: 'Pillowcase' },
		{ img: 'assets/imgs/shop/category-thumb-7.jpg', name: 'Jumpsuits' },
		{ img: 'assets/imgs/shop/category-thumb-8.jpg', name: 'Hats' },
	];

	return (
		<section className='popular-categories section-padding mt-15 mb-25'>
			<div className='container wow fadeIn animated'>
				<h3 className='section-title mb-20'>
					<span>Popular</span> Categories
				</h3>
				<Swiper
					modules={[Navigation, Pagination, Autoplay]}
					navigation
					pagination={{ clickable: true }}
					spaceBetween={20}
					slidesPerView={matches ? 1 : 4}
					autoplay={{ delay: 1000, disableOnInteraction: false }}
					loop={true}
					breakpoints={{
						640: { slidesPerView: 2 },
						768: { slidesPerView: 3 },
						1024: { slidesPerView: 4 },
					}}
					className='carausel-6-columns'>
					{category?.result?.map((item, index) => (
						<SwiperSlide key={index}>
							<div
								className='card-1'
								onClick={() =>
									loginInfo?.status === 'success' &&
									loginInfo?.result?.status === '1'
										? navigate(`/product`, {
												state: item,
										  })
										: navigate('/login')
								}>
								<figure className='img-hover-scale overflow-hidden'>
									<a>
										<img
											src={item.imgpath}
											alt={item.imgpath}
											// style={{ height: 300 }}
										/>
									</a>
								</figure>
								<h5>
									<a>{item.name}</a>
								</h5>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
};

export default PopularCategories;
