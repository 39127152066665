import React, { useEffect, useState } from 'react';
import {
	Modal,
	Box,
	Button,
	Typography,
	Grid,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../reducers/UiReducer';
import { showBankDispatch } from '../../reducers/HomeReducer';

const BasicModal = () => {
	const [open, setOpen] = useState(false);
	const { modal } = useSelector((state) => state.ui);
	const { bank } = useSelector((state) => state.home);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	// useEffect(() => {
	// 	dispatch(showBankDispatch());
	// }, []);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		dispatch(toggleModal(false));
	};

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: matches ? 325 : 1000,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	return (
		<div>
			<Modal open={modal} onClose={handleClose}>
				<Box sx={style}>
					<Grid container alignItems={'center'} spacing={2}>
						<Grid item xs={12} lg={6}>
							<img src={bank?.result[0]?.imgpath} />
						</Grid>
						<Grid item xs={12} lg={6}>
							<table>
								<tr>
									<th>Name</th>
									<td>{bank?.result[0]?.account_name}</td>
								</tr>
								<tr>
									<th>Bank</th>
									<td>{bank?.result[0]?.bank_name}</td>
								</tr>
								<tr>
									<th>Account Number</th>
									<td>{bank?.result[0]?.account_number}</td>
								</tr>
								<tr>
									<th>Branch</th>
									<td>{bank?.result[0]?.branch_name}</td>
								</tr>
								<tr>
									<th>IFSC Code</th>
									<td>{bank?.result[0]?.ifsc_code}</td>
								</tr>
							</table>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
};

export default BasicModal;
