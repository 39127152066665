import React, { useEffect, useState } from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from '../forms/LoginForm';
import { Link, useNavigate } from 'react-router-dom';
import PaymentMethod from '../components/resuableComponents/PaymentMethod';
import {
	addOrderDispatch,
	bannerDispatch,
	customeridDispatch,
	fetchpaymentDispatch,
	markupDispatch,
	showBankDispatch,
} from '../reducers/HomeReducer';
import BasicModal from '../components/resuableComponents/BasicModal';
import { toggleModal } from '../reducers/UiReducer';
import Swal from 'sweetalert2';
import ShippingForm from '../forms/ShippingForm';

const CheckoutPage = () => {
	const { cart, modal } = useSelector((state) => state.ui);
	const { loginInfo, loading, markup } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const [first_name, setFirstName] = useState(loginInfo?.result?.firstname);
	const [last_name, setLastName] = useState(loginInfo?.result?.lastname);
	const [address, setAddress] = useState(loginInfo?.result?.address);
	const [city, setCity] = useState(loginInfo?.result?.city);
	const [state, setState] = useState(loginInfo?.result?.state);
	const [pin, setPin] = useState(loginInfo?.result?.pin);
	const [file, setFile] = useState(loginInfo?.result?.firstname);
	const [email, setEmail] = useState(loginInfo?.result?.email);
	const [phone, setPhone] = useState(loginInfo?.result?.phone);
	const navigate = useNavigate();
	const [paymentOption, setPaymentOption] = useState('wallet');

	// Handle change of selected payment option
	console.log('paymentOption', paymentOption);
	useEffect(() => {
		dispatch(markupDispatch());
		dispatch(bannerDispatch());
		dispatch(showBankDispatch());
		dispatch(customeridDispatch(loginInfo?.result?.id));
	}, []);
	if (loading) {
		return (
			<div id='preloader-active'>
				<div class='preloader d-flex align-items-center justify-content-center'>
					<div class='preloader-inner position-relative'>
						<div class='text-center'>
							<h5 class='mb-5'>Now Loading</h5>
							<div class='loader'>
								<div class='bar bar1'></div>
								<div class='bar bar2'></div>
								<div class='bar bar3'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<>
			<Header />
			<main class='main'>
				<div class='page-header breadcrumb-wrap'>
					<div class='container'>
						<div class='breadcrumb'>
							<a href='index.html' rel='nofollow'>
								Home
							</a>
							<span></span> Shop
							<span></span> Checkout
						</div>
					</div>
				</div>
				<section class='mt-50 mb-50'>
					<div class='container'>
						<div class='row'>
							<div class='col-md-6'>
								<div class='order_review'>
									<div class='mb-20'>
										<h4>Your Orders</h4>
									</div>
									<div class='table-responsive order_table text-center'>
										<table class='table'>
											<thead>
												<tr>
													<th colspan='2'>Product</th>
													<th>Total</th>
												</tr>
											</thead>
											<tbody>
												{cart?.map((i) => {
													return (
														<tr>
															<td class='image product-thumbnail'>
																<Link
																	to={
																		loginInfo?.status === 'success' &&
																		loginInfo?.result?.status === '1'
																			? `/productdetails/?name=${i.name}&id=${i.id}`
																			: ''
																	}>
																	<img src={i.imgpath} alt='#' />
																	<br />
																	<span>Size Preference :- {i.size}</span>
																</Link>
															</td>
															<td>
																<h5>
																	<Link
																		to={
																			loginInfo?.status === 'success' &&
																			loginInfo?.result?.status === '1'
																				? `/productdetails/?name=${i.name}&id=${i.id}`
																				: ''
																		}>
																		<a>{i.name}</a>
																	</Link>
																</h5>{' '}
																<span class='product-qty'>
																	x{' '}
																	{`${i.qnty}=${
																		Number(i.set_quantity) * Number(i.qnty)
																	}`}
																</span>
															</td>
															<td>
																₹
																{Number(
																	Math.round(
																		Number(
																			Number(i.set_quantity) * Number(i.qnty)
																		) *
																			Number(
																				Number(i?.price_per) +
																					Number(
																						Number(
																							loginInfo?.result?.role ===
																								'Credit' || 'Premium'
																								? i?.price_per
																								: 0
																						) *
																							Number(
																								parseInt(
																									loginInfo?.result?.role ===
																										'Credit'
																										? markup?.result[0]?.markup
																										: markup?.result[0]
																												?.markup_premium
																								)
																							)
																					) /
																						100
																			)
																	)
																)}
															</td>
														</tr>
													);
												})}
												<tr>
													<th>SubTotal</th>
													<td class='product-subtotal' colspan='2'>
														₹
														{Number(
															cart.reduce((accumulator, currentValue) => {
																return (
																	accumulator +
																	parseFloat(
																		Number(
																			Math.round(
																				Number(
																					Math.round(
																						Number(
																							Number(
																								currentValue.set_quantity
																							) * Number(currentValue.qnty)
																						) * Number(currentValue.price_per)
																					)
																				) +
																					Number(
																						Number(
																							Number(
																								loginInfo?.result?.role ===
																									'Credit' || 'Premium'
																									? Number(
																											Number(
																												currentValue.set_quantity
																											) *
																												Number(
																													currentValue.qnty
																												)
																									  ) *
																											Number(
																												currentValue.price_per
																											)
																									: 0
																							) *
																								(Number(
																									parseInt(
																										loginInfo?.result?.role ===
																											'Credit'
																											? markup?.result[0]
																													?.markup
																											: markup?.result[0]
																													?.markup_premium
																									)
																								) /
																									100) // Divide by 100 for percentage
																						)
																					)
																			)
																		)
																	)
																);
															}, 0)
														)}
													</td>
												</tr>
												<tr>
													<th>Shipping</th>
													<td colspan='2'>
														<em>Free Shipping</em>
													</td>
												</tr>
												<tr>
													<td>{`Gst (${markup?.result[0]?.gst}%)`}</td>
													<td colspan='2'>
														₹<i className='ti-gift mr-5'></i>{' '}
														{Number(
															cart.reduce((accumulator, currentValue) => {
																return (
																	accumulator +
																	parseFloat(
																		Number(
																			Math.round(
																				Number(
																					Math.round(
																						Number(
																							Number(
																								Math.round(
																									Number(
																										Math.round(
																											Number(
																												Number(
																													currentValue.set_quantity
																												) *
																													Number(
																														currentValue.qnty
																													)
																											) *
																												Number(
																													currentValue.price_per
																												)
																										)
																									) +
																										Number(
																											Number(
																												Number(
																													loginInfo?.result
																														?.role ===
																														'Credit' ||
																														'Premium'
																														? Number(
																																Number(
																																	currentValue.set_quantity
																																) *
																																	Number(
																																		currentValue.qnty
																																	)
																														  ) *
																																Number(
																																	currentValue.price_per
																																)
																														: 0
																												) *
																													(Number(
																														parseInt(
																															loginInfo?.result
																																?.role ===
																																'Credit'
																																? markup
																																		?.result[0]
																																		?.markup
																																: markup
																																		?.result[0]
																																		?.markup_premium
																														)
																													) /
																														100) // Divide by 100 for percentage
																											)
																										)
																								)
																							)
																						) *
																							Number(
																								parseInt(markup?.result[0]?.gst)
																							)
																					)
																				) / 100
																			)
																		)
																	)
																);
															}, 0)
														)}
													</td>
												</tr>
												<tr>
													<th>Total</th>
													<td colspan='2' class='product-subtotal'>
														<span class='font-xl text-brand fw-900'>
															₹
															{Number(
																cart.reduce((accumulator, currentValue) => {
																	return (
																		accumulator +
																		parseFloat(
																			Number(
																				Math.round(
																					Number(
																						Math.round(
																							Number(
																								Number(
																									currentValue.set_quantity
																								) * Number(currentValue.qnty)
																							) * Number(currentValue.price_per)
																						)
																					) +
																						Number(
																							Number(
																								Number(
																									loginInfo?.result?.role ===
																										'Credit' || 'Premium'
																										? Number(
																												Number(
																													currentValue.set_quantity
																												) *
																													Number(
																														currentValue.qnty
																													)
																										  ) *
																												Number(
																													currentValue.price_per
																												)
																										: 0
																								) *
																									(Number(
																										parseInt(
																											loginInfo?.result
																												?.role === 'Credit'
																												? markup?.result[0]
																														?.markup
																												: markup?.result[0]
																														?.markup_premium
																										)
																									) /
																										100) // Divide by 100 for percentage
																							)
																						)
																				)
																			)
																		)
																	);
																}, 0)
															) +
																Number(
																	cart.reduce((accumulator, currentValue) => {
																		return (
																			accumulator +
																			parseFloat(
																				Number(
																					Math.round(
																						Number(
																							Math.round(
																								Number(
																									Number(
																										Math.round(
																											Number(
																												Math.round(
																													Number(
																														Number(
																															currentValue.set_quantity
																														) *
																															Number(
																																currentValue.qnty
																															)
																													) *
																														Number(
																															currentValue.price_per
																														)
																												)
																											) +
																												Number(
																													Number(
																														Number(
																															loginInfo?.result
																																?.role ===
																																'Credit' ||
																																'Premium'
																																? Number(
																																		Number(
																																			currentValue.set_quantity
																																		) *
																																			Number(
																																				currentValue.qnty
																																			)
																																  ) *
																																		Number(
																																			currentValue.price_per
																																		)
																																: 0
																														) *
																															(Number(
																																parseInt(
																																	loginInfo
																																		?.result
																																		?.role ===
																																		'Credit'
																																		? markup
																																				?.result[0]
																																				?.markup
																																		: markup
																																				?.result[0]
																																				?.markup_premium
																																)
																															) /
																																100) // Divide by 100 for percentage
																													)
																												)
																										)
																									)
																								) *
																									Number(
																										parseInt(
																											markup?.result[0]?.gst
																										)
																									)
																							)
																						) / 100
																					)
																				)
																			)
																		);
																	}, 0)
																)}
														</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div class='bt-1 border-color-1 mt-30 mb-30'></div>

									{/* {loginInfo?.token && (
										<a
											onClick={handleSubmit}
											class='btn btn-fill-out btn-block mt-30'>
											Place Order
										</a>
									)} */}
								</div>
							</div>
							<div class='col-md-6'>
								<ShippingForm />
							</div>
						</div>
					</div>
				</section>
			</main>
			<BasicModal />
			<Footer />
		</>
	);
};

export default CheckoutPage;
