import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	addCartRow,
	addWhitelistRow,
	setProductDetail,
} from '../../reducers/UiReducer';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const ProductCard = ({ i, loginInfo, markup }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { cart } = useSelector((state) => state.ui);
	return (
		<>
			<div class='col-lg-4 col-md-4 col-12 col-sm-6'>
				<div className='product-cart-wrap mb-30'>
					<div className='product-img-action-wrap'>
						<div className='product-img product-img-zoom'>
							<Link
								to={
									loginInfo?.status === 'success' &&
									loginInfo?.result?.status === '1'
										? `/productdetails/?name=${i.name}&id=${i.id}`
										: '/login'
								}>
								<a>
									<img
										className='default-img'
										src={i.imgpath}
										style={{
											width: '100%',
											height: 400,
											// objectFit: 'fill',
											borderRadius: 5,
										}}
										alt=''
									/>
									<img
										className='hover-img'
										src='assets/imgs/shop/product-1-2.jpg'
										alt=''
									/>
								</a>
							</Link>
						</div>
						<div className='product-action-1'>
							{/* <a
                                                                                                                                    aria-label='View'
                                                                                                                                    className='action-btn hover-up'
                                                                                                                                    data-bs-toggle='modal'
                                                                                                                                    data-bs-target='#quickViewModal'>
                                                                                                                                    <i className='fi-rs-eye'></i>
                                                                                                                                </a> */}
							<a
								onClick={() => {
									if (
										loginInfo?.status === 'success' &&
										loginInfo?.result?.status === '1'
									) {
										dispatch(addWhitelistRow(i));
										navigate('/whishlist');
									} else {
										navigate('/login');
									}
								}}
								aria-label='Add To Wishlist'
								className='action-btn hover-up'>
								<i className='fi-rs-heart'></i>
							</a>
							{/* <a
                                                                                                                            aria-label='Compare'
                                                                                                                            className='action-btn hover-up'
                                                                                                                            href='shop-compare.html'>
                                                                                                                            <i className='fi-rs-shuffle'></i>
                                                                                                                        </a> */}
						</div>
						{i.total_quantity < i.min_order ? (
							<div className='product-badges product-badges-position product-badges-mrg'>
								<span className='hot'>OUT OF STOCK</span>
							</div>
						) : (
							''
						)}
					</div>
					<div className='product-content-wrap'>
						<div className='product-category'>
							<a href='shop-grid-right.html'>{i.category?.name}</a>
						</div>
						<h2>{i.name}</h2>
						{/* <div className='rating-result' title='90%'>
                                                                                                                                <span>
                                                                                                                                    <span>90%</span>
                                                                                                                                </span>
                                                                                                                            </div> */}
						<div className='product-price'>
							{loginInfo?.status === 'success' &&
								loginInfo?.result?.status === '1' && (
									<span>
										₹
										{Number(
											Math.round(
												Number(i.price_per) +
													Number(
														Number(
															loginInfo?.result?.role === 'Credit' || 'Premium'
																? i.price_per
																: 0
														) *
															Number(
																parseInt(
																	loginInfo?.result?.role === 'Credit'
																		? markup?.result[0]?.markup
																		: markup?.result[0]?.markup_premium
																)
															)
													) /
														100
											)
										)}
									</span>
								)}
							{/* <span className='old-price'>$245.8</span> */}
						</div>
						<div
							className='product-action-1 show'
							onClick={() => {
								if (i.total_quantity < i.min_order) {
									Swal.fire({
										title: 'OUT OF STOCK',
										icon: 'info',
										showCloseButton: true,
										showCancelButton: true,
										focusConfirm: false,
										confirmButtonAriaLabel: 'Thumbs up, great!',
										cancelButtonAriaLabel: 'Thumbs down',
									});
								} else {
									if (
										loginInfo?.status === 'success' &&
										loginInfo?.result?.status === '1'
									) {
										const exists = cart?.some((obj) => obj.id === i.id);
										if (exists) {
											navigate('/cart');
										} else {
											dispatch(addCartRow(i));
											Swal.fire({
												position: 'top-end',
												icon: 'success',
												title: 'Added To Cart',
												text: 'Product has been added to your cart!',
												showConfirmButton: false,
												timer: 1500,
											});
										}
									} else {
										navigate('/login');
									}
								}
							}}>
							<a
								aria-label={
									cart?.some((obj) => obj.id === i.id)
										? 'Go To Cart'
										: 'Add To Cart'
								}
								className='action-btn hover-up'>
								<i className='fi-rs-shopping-bag-add'></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductCard;
