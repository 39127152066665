import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './components/MainRouter';
import store from './Store';
import { useEffect } from 'react';
import { generateToken } from './notification/firebase';
import { onMessage } from 'firebase/messaging';
function App() {
	useEffect(() => {
		// generateToken();
		onMessage(MessageChannel, (payload) => {
			console.log('payload', payload);
		});
	}, []);
	return (
		<>
			<Provider store={store}>
				<BrowserRouter>
					<MainRouter />
				</BrowserRouter>
			</Provider>
		</>
	);
}

export default App;
