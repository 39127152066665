import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function CustomerTranTable() {
	const { product, loginInfo, customer, custTrans } = useSelector(
		(state) => state.home
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const columns = [
		{ field: 'id', headerName: 'SNo.', width: 100 },
		{
			field: 'orderId',
			headerName: 'Order ID',
			width: 200,
		},
		{
			field: 'desc',
			headerName: 'Description',
			width: 300,
		},
		{
			field: 'Name',
			headerName: 'Name',
			width: 200,
			renderCell: (cellValues) => {
				console.log('cellValue', cellValues);
				return (
					<Box mt={2}>
						<p
							style={{}}>{`${cellValues.row.customer.firstname} ${cellValues.row.customer.lastname}`}</p>
					</Box>
				);
			},
		},
		{
			field: 'cust_id',
			headerName: 'Customer ID',
			width: 150,
		},
		{
			field: 'amount',
			headerName: 'Amount',
			width: 200,
		},
		{
			field: 'avial_cust_bal',
			headerName: 'Avialable Balance',
			width: 200,
		},
		{
			field: 'od_cust_bal',
			headerName: 'Credit Balance',
			width: 200,
			renderCell: (cellValues) => {
				console.log('cellValue', cellValues);
				return (
					<Box mt={2}>
						<p style={{}}>{`-${cellValues.row.od_cust_bal} `}</p>
					</Box>
				);
			},
		},
		{
			field: 'security_cust_bal',
			headerName: 'Security Balance',
			width: 200,
			renderCell: (cellValues) => {
				console.log('cellValue', cellValues);
				return (
					<Box mt={2}>
						<p style={{}}>{`${cellValues.row.security_cust_bal} `}</p>
					</Box>
				);
			},
		},
		{
			field: 'tran_type',
			headerName: 'Tran Type',
			width: 150,
		},
		{
			field: 'mode',
			headerName: 'Mode',
			width: 150,
		},
		{
			field: 'created_at',
			headerName: 'Date',
			width: 300,
			editable: true,
			renderCell: (cellValues) => {
				return (
					<Box mt={2}>
						<p style={{ fontSize: 14 }}>{`${
							cellValues.row.created_at.split('T')[0].split('-')[2]
						}-${cellValues.row.created_at.split('T')[0].split('-')[1]}-${
							cellValues.row.created_at.split('T')[0].split('-')[0]
						} / ${cellValues.row.created_at.split('T')[1].split('.')[0]}`}</p>
					</Box>
				);
			},
		},
		// {
		// 	field: 'description',
		// 	headerName: 'Description',
		// 	// type: 'number',
		// 	width: 150,
		// 	editable: true,
		// },
		// {
		// 	field: 'categoryId',
		// 	headerName: 'Category Id',
		// 	width: 300,
		// 	editable: true,
		// },
	];
	const handleClick = (event, cellValues) => {};
	const handleClickDelete = (event, cellValues) => {
		// dispatch(deleteproductDispatch(cellValues.id, loginInfo.token));
	};
	return (
		<Box sx={{ height: 400, width: '100%' }}>
			{custTrans?.result.length > 0 ? (
				<DataGrid
					sx={{
						'&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
							outline: 'none',
						},
						'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
							outline: 'none',
						},
						// Custom scrollbar styles
						'& .MuiDataGrid-virtualScroller': {
							'&::-webkit-scrollbar': {
								height: '8px',
								backgroundColor: '#f5f5f5',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#888',
								borderRadius: '4px',
							},
							'&::-webkit-scrollbar-thumb:hover': {
								backgroundColor: '#555',
							},
						},
					}}
					rows={custTrans?.result}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					disableRowSelectionOnClick
				/>
			) : (
				<h3 style={{ fontSize: 20 }}>No Record Found</h3>
			)}
		</Box>
	);
}
