import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import {
	loginDispatch,
	resetPasswordDispatch,
	sendResetLinkDispatch,
} from '../reducers/HomeReducer';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const LoginForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isForgotPassword, setIsForgotPassword] = useState(false);

	// Validation Schemas
	const loginValidationSchema = Yup.object({
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
		password: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.required('Password is required'),
		checkbox: Yup.boolean(),
	});

	const forgotPasswordValidationSchema = Yup.object({
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
	});

	// Initial Values
	const loginInitialValues = {
		email: '',
		password: '',
		checkbox: false,
	};

	const forgotPasswordInitialValues = {
		email: '',
	};

	// Form Submission Handlers
	const handleLoginSubmit = (values) => {
		console.log('Login Data:', values);
		dispatch(loginDispatch(values, navigate, Swal));
	};

	const handleForgotPasswordSubmit = (values) => {
		console.log('Forgot Password Data:', values);
		dispatch(sendResetLinkDispatch(values, Swal));
		// Swal.fire({
		// 	icon: 'success',
		// 	title: 'Reset link sent',
		// 	text: `A password reset link has been sent to ${values.email}`,
		// });
	};

	return (
		<>
			{isForgotPassword ? (
				<Formik
					initialValues={forgotPasswordInitialValues}
					validationSchema={forgotPasswordValidationSchema}
					onSubmit={handleForgotPasswordSubmit}>
					{({ isSubmitting }) => (
						<Form>
							<div className='form-group'>
								<Field
									type='text'
									name='email'
									placeholder='Your Email'
									className='form-control'
								/>
								<ErrorMessage
									name='email'
									component='div'
									className='error-message'
								/>
							</div>

							<div className='form-group'>
								<button
									type='submit'
									className='btn btn-fill-out btn-block hover-up'
									disabled={isSubmitting}>
									Send Reset Link
								</button>
							</div>

							<div className='form-group text-center'>
								<button
									type='button'
									// className='btn btn-link'
									onClick={() => setIsForgotPassword(false)}>
									Back to Login
								</button>
							</div>
						</Form>
					)}
				</Formik>
			) : (
				<Formik
					initialValues={loginInitialValues}
					validationSchema={loginValidationSchema}
					onSubmit={handleLoginSubmit}>
					{({ isSubmitting }) => (
						<Form>
							<div className='form-group'>
								<Field
									type='text'
									name='email'
									placeholder='Your Email'
									className='form-control'
								/>
								<ErrorMessage
									name='email'
									component='div'
									className='error-message'
								/>
							</div>

							<div className='form-group'>
								<Field
									type='password'
									name='password'
									placeholder='Password'
									className='form-control'
								/>
								<ErrorMessage
									name='password'
									component='div'
									className='error-message'
								/>
							</div>

							<div className='login_footer form-group'>
								<div className='chek-form'>
									{/* <div className='custome-checkbox'>
										<Field
											type='checkbox'
											name='checkbox'
											id='exampleCheckbox1'
											className='form-check-input'
										/>
										<label
											className='form-check-label'
											htmlFor='exampleCheckbox1'>
											<span>Remember me</span>
										</label>
									</div> */}
								</div>
								<button
									type='button'
									// className='btn-link text-muted'
									onClick={() => setIsForgotPassword(true)}>
									Forgot password?
								</button>
							</div>

							<div className='form-group'>
								<button
									type='submit'
									className='btn btn-fill-out btn-block hover-up'
									disabled={isSubmitting}>
									Log in
								</button>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default LoginForm;
