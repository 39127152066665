import React, { useEffect } from 'react';
import Header from '../components/resuableComponents/Header';
import MobileHeader from '../components/resuableComponents/MobileHeader';
import MainPart from '../components/resuableComponents/MainPart';
import Footer from '../components/resuableComponents/Footer';
import { useDispatch, useSelector } from 'react-redux';
import {
	bannerDispatch,
	categoryDispatch,
	customeridDispatch,
	loggedDispatch,
	notificationDispatch,
	showAdsDispatch,
	showBottomDispatch,
	showfeaturesDispatch,
	showSingleDispatch,
} from '../reducers/HomeReducer';

const LandingPage = () => {
	const dispatch = useDispatch();
	const { loginInfo } = useSelector((state) => state.home);
	useEffect(() => {
		dispatch(categoryDispatch());
		dispatch(bannerDispatch());
		dispatch(showfeaturesDispatch());
		dispatch(showAdsDispatch());
		dispatch(showBottomDispatch());
		dispatch(showSingleDispatch());
		dispatch(customeridDispatch(loginInfo?.result?.id));
		dispatch(notificationDispatch(loginInfo?.result?.id));
	}, []);
	return (
		<>
			<Header />
			<MobileHeader />
			<MainPart />
			<Footer />
		</>
	);
};

export default LandingPage;
