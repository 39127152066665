import React, { useEffect } from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import LoginForm from '../forms/LoginForm';
import { useSelector } from 'react-redux';
import RegistrationForm from '../forms/RegistrationForm';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
	const { loading, loginInfo } = useSelector((state) => state.home);
	const navigate = useNavigate();
	useEffect(() => {
		if (loginInfo?.token) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
	}, []);
	if (loading) {
		return (
			<div id='preloader-active'>
				<div class='preloader d-flex align-items-center justify-content-center'>
					<div class='preloader-inner position-relative'>
						<div class='text-center'>
							<h5 class='mb-5'>Now Loading</h5>
							<div class='loader'>
								<div class='bar bar1'></div>
								<div class='bar bar2'></div>
								<div class='bar bar3'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<>
			<Header />
			<div>
				<main className='main'>
					<div className='page-header breadcrumb-wrap'>
						<div className='container'>
							<div className='breadcrumb'>
								<a href='index.html' rel='nofollow'>
									Home
								</a>
								<span></span> Pages <span></span> Login / Register
							</div>
						</div>
					</div>
					<section className='pt-50 pb-150'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-10 m-auto'>
									<div className='row'>
										<div className='col-lg-5'>
											<div className='login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5'>
												<div className='padding_eight_all bg-white'>
													<div className='heading_s1'>
														<h3 className='mb-30'>Login</h3>
													</div>
													<LoginForm />
												</div>
											</div>
										</div>
										<div className='col-lg-1'></div>
										<div className='col-lg-6'>
											<div className='login_wrap widget-taber-content p-30 background-white border-radius-5'>
												<div className='padding_eight_all bg-white'>
													<div className='heading_s1'>
														<h3 className='mb-30'>Create an Account</h3>
													</div>
													<p className='mb-50 font-sm'>
														Your personal data will be used to support your
														experience throughout this website, to manage access
														to your account, and for other purposes described in
														our privacy policy
													</p>
													<RegistrationForm />
													<div className='divider-text-center mt-15 mb-15'>
														<span> or</span>
													</div>
													{/* <ul className='btn-login list_none text-center mb-15'>
														<li>
															<a
																href='#'
																className='btn btn-facebook hover-up mb-lg-0 mb-sm-4'>
																Login With Facebook
															</a>
														</li>
														<li>
															<a href='#' className='btn btn-google hover-up'>
																Login With Google
															</a>
														</li>
													</ul> */}
													<div
														className='text-muted text-center'
														onClick={() => window.scrollTo(0, 0)}>
														Already have an account? <a>Sign in now</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</div>
			<Footer />
		</>
	);
};

export default LoginPage;
