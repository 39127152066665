import React, { useEffect } from 'react';
import HeroSlider from './HeroSlider';
import PopularCategories from './PopularCategories';
import NewArrivalsCarousel from './NewArrivalsCarousel';
import MonthlyCarousel from './MonthlyCarousel';
import { useDispatch, useSelector } from 'react-redux';
import {
	markupDispatch,
	productDispatch,
	productPremiumDispatch,
} from '../../reducers/HomeReducer';
import { addCartRow, addWhitelistRow } from '../../reducers/UiReducer';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ProdCart from './ProdCard';

const MainPart = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		product,
		loginInfo,
		productPremium,
		markup,
		features,
		single,
		ads,
		bottom,
	} = useSelector((state) => state.home);
	let data = [];
	if (product?.result?.length > 0 && productPremium?.result?.length > 0) {
		data = [...productPremium?.result, ...product?.result];
	}
	console.log('data', data);
	useEffect(() => {
		if (loginInfo?.result?.role === 'Premium') {
			dispatch(productPremiumDispatch());
		}
		dispatch(productDispatch());
		dispatch(markupDispatch());
	}, []);

	return (
		<>
			<main className='main'>
				<HeroSlider />
				<section className='featured section-padding position-relative'>
					<div className='container'>
						<div className='row'>
							{features?.result?.map((i) => {
								return (
									<div className='col-lg-2 col-md-4 mb-md-3 mb-lg-0'>
										<div className='banner-features wow fadeIn animated hover-up'>
											<img src={i?.imgpath} alt='' style={{ height: 130 }} />
											<h4 className='bg-1'>{i.title}</h4>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</section>
				<section className='product-tabs section-padding position-relative wow fadeIn animated'>
					<div className='bg-square'></div>
					<div className='container'>
						<div className='tab-header'>
							<ul className='nav nav-tabs' id='myTab' role='tablist'>
								<li className='nav-item' role='presentation'>
									<button
										className='nav-link active'
										id='nav-tab-one'
										data-bs-toggle='tab'
										data-bs-target='#tab-one'
										type='button'
										role='tab'
										aria-controls='tab-one'
										aria-selected='true'>
										New added
									</button>
								</li>
								{/* <li className='nav-item' role='presentation'>
									<button
										className='nav-link'
										id='nav-tab-two'
										data-bs-toggle='tab'
										data-bs-target='#tab-two'
										type='button'
										role='tab'
										aria-controls='tab-two'
										aria-selected='false'>
										Popular
									</button>
								</li>
								<li className='nav-item' role='presentation'>
									<button
										className='nav-link'
										id='nav-tab-three'
										data-bs-toggle='tab'
										data-bs-target='#tab-three'
										type='button'
										role='tab'
										aria-controls='tab-three'
										aria-selected='false'>
										New added
									</button>
								</li> */}
							</ul>
							<Link to='/product'>
								<a className='view-more d-none d-md-flex'>
									View More<i className='fi-rs-angle-double-small-right'></i>
								</a>
							</Link>
						</div>
						{/* <!--End nav-tabs--> */}
						<div className='tab-content wow fadeIn animated' id='myTabContent'>
							<div
								className='tab-pane fade show active'
								id='tab-one'
								role='tabpanel'
								aria-labelledby='tab-one'>
								<div className='row product-grid-4'>
									{data?.length > 0
										? data?.map((i, index) => {
												return index < 4 ? (
													<ProdCart
														i={i}
														loginInfo={loginInfo}
														markup={markup}
													/>
												) : (
													''
												);
										  })
										: product?.result?.map((i, index) => {
												return index < 4 ? (
													<ProdCart
														i={i}
														loginInfo={loginInfo}
														markup={markup}
													/>
												) : (
													''
												);
										  })}

									{/* <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-2-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-2-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='new'>New</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Clothing</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Plain Color Pocket Shirts
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>50%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$138.85 </span>
													<span className='old-price'>$255.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-3-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-3-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='best'>Best Sell</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Shirts</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Vintage Floral Oil Shirts
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>95%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$338.85 </span>
													<span className='old-price'>$445.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-4-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-4-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='sale'>Sale</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Clothing</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Colorful Hawaiian Shirts
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$123.85 </span>
													<span className='old-price'>$235.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-xs-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-5-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-5-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='hot'>-30%</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Shirt</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Flowers Sleeve Lapel Shirt
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$28.85 </span>
													<span className='old-price'>$45.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-xs-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-6-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-6-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='hot'>-22%</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Shirts</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Ethnic Floral Casual Shirts
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$238.85 </span>
													<span className='old-price'>$245.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-xs-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-7-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-7-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='new'>New</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Shoes</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Stitching Hole Sandals
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>98%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$1275.85 </span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-8-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-8-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Shirt</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Mens Porcelain Shirt
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$238.85 </span>
													<span className='old-price'>$245.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div> */}
								</div>
								{/* <!--End product-grid-4--> */}
							</div>
							{/* <!--En tab one (Featured)--> */}
							<div
								className='tab-pane fade'
								id='tab-two'
								role='tabpanel'
								aria-labelledby='tab-two'>
								<div className='row product-grid-4'>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-9-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-9-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='hot'>Hot</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Donec </a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Lorem ipsum dolor
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>90%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$238.85 </span>
													<span className='old-price'>$245.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-10-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-10-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='new'>New</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Music</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Sed tincidunt interdum
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>50%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$138.85 </span>
													<span className='old-price'>$255.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-11-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-11-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='best'>Best Sell</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Watch</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>Fusce metus orci</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>95%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$338.85 </span>
													<span className='old-price'>$445.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-12-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-12-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='sale'>Sale</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Music</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Integer venenatis libero
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$123.85 </span>
													<span className='old-price'>$235.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-13-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-13-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='hot'>-30%</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Speaker</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Cras tempor orci id
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$28.85 </span>
													<span className='old-price'>$45.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-14-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-14-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='hot'>-22%</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Camera</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Nullam cursus mi qui
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$238.85 </span>
													<span className='old-price'>$245.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-15-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-15-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='new'>New</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Phone</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Fusce fringilla ultrices
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>98%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$1275.85 </span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-1-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-1-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Accessories </a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Sed sollicitudin est
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$238.85 </span>
													<span className='old-price'>$245.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <!--End product-grid-4--> */}
							</div>
							{/* <!--En tab two (Popular)--> */}
							<div
								className='tab-pane fade'
								id='tab-three'
								role='tabpanel'
								aria-labelledby='tab-three'>
								<div className='row product-grid-4'>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='default-img'
															src='assets/imgs/shop/product-2-1.jpg'
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-2-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='hot'>Hot</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Music</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Donec ut nisl rutrum
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>90%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$238.85 </span>
													<span className='old-price'>$245.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-3-1.jpg'
															alt=''
														/>
														<img
															className='default-img'
															src='assets/imgs/shop/product-3-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='new'>New</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Music</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Nullam dapibus pharetra
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>50%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$138.85 </span>
													<span className='old-price'>$255.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-4-1.jpg'
															alt=''
														/>
														<img
															className='default-img'
															src='assets/imgs/shop/product-4-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='best'>Best Sell</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Watch</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Morbi dictum finibus
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>95%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$338.85 </span>
													<span className='old-price'>$445.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-5-1.jpg'
															alt=''
														/>
														<img
															className='default-img'
															src='assets/imgs/shop/product-5-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='sale'>Sale</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Music</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Nunc volutpat massa
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$123.85 </span>
													<span className='old-price'>$235.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-6-1.jpg'
															alt=''
														/>
														<img
															className='default-img'
															src='assets/imgs/shop/product-6-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='hot'>-30%</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Speaker</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Nullam ultricies luctus
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$28.85 </span>
													<span className='old-price'>$45.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-7-1.jpg'
															alt=''
														/>
														<img
															className='default-img'
															src='assets/imgs/shop/product-7-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='hot'>-22%</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Camera</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Nullam mattis enim
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$238.85 </span>
													<span className='old-price'>$245.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-8-1.jpg'
															alt=''
														/>
														<img
															className='default-img'
															src='assets/imgs/shop/product-8-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='new'>New</span>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Phone</a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														Vivamus sollicitudin
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>98%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$1275.85 </span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-4 col-12 col-sm-6'>
										<div className='product-cart-wrap mb-30'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<a href='shop-product-right.html'>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-9-1.jpg'
															alt=''
														/>
														<img
															className='default-img'
															src='assets/imgs/shop/product-9-2.jpg'
															alt=''
														/>
													</a>
												</div>
												<div className='product-action-1'>
													<a
														aria-label='Quick view'
														className='action-btn hover-up'
														data-bs-toggle='modal'
														data-bs-target='#quickViewModal'>
														<i className='fi-rs-eye'></i>
													</a>
													<a
														aria-label='Add To Wishlist'
														className='action-btn hover-up'
														href='shop-wishlist.html'>
														<i className='fi-rs-heart'></i>
													</a>
													<a
														aria-label='Compare'
														className='action-btn hover-up'
														href='shop-compare.html'>
														<i className='fi-rs-shuffle'></i>
													</a>
												</div>
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>Accessories </a>
												</div>
												<h2>
													<a href='shop-product-right.html'>
														{' '}
														Donec ut nisl rutrum
													</a>
												</h2>
												<div className='rating-result' title='90%'>
													<span>
														<span>70%</span>
													</span>
												</div>
												<div className='product-price'>
													<span>$238.85 </span>
													<span className='old-price'>$245.8</span>
												</div>
												<div className='product-action-1 show'>
													<a
														aria-label='Add To Cart'
														className='action-btn hover-up'
														href='shop-cart.html'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <!--End product-grid-4--> */}
							</div>
							{/* <!--En tab three (New added)--> */}
						</div>
						{/* <!--End tab-content--> */}
					</div>
				</section>
				<section className='banner-2 section-padding pb-0'>
					<div className='container'>
						<div className='banner-img banner-big wow fadeIn animated f-none'>
							<img
								style={{
									width: '100%',
									height: 200,
									objectFit: 'fill',
									borderRadius: 5,
								}}
								src={single?.result[0]?.imgpath}
								alt=''
							/>
							{/* <div className='banner-text d-md-block d-none'>
								<h4 className='mb-15 mt-40 text-brand'>Repair Services</h4>
								<h1 className='fw-600 mb-20'>
									We're an Apple <br />
									Authorised Service Provider
								</h1>
								<a href='shop-grid-right.html' className='btn'>
									Learn More <i className='fi-rs-arrow-right'></i>
								</a>
							</div> */}
						</div>
					</div>
				</section>
				<PopularCategories />
				<section className='banners mb-15'>
					<div className='container'>
						<div className='row'>
							{ads?.result?.map((i) => {
								return (
									<div className='col-lg-4 col-md-6'>
										<div className='banner-img wow fadeIn animated'>
											<img
												style={{
													width: '100%',
													height: 200,
													objectFit: 'fill',
													borderRadius: 5,
												}}
												src={i.imgpath}
												alt=''
											/>
										</div>
									</div>
								);
							})}

							{/* <div className='col-lg-4 col-md-6'>
								<div className='banner-img wow fadeIn animated'>
									<img
										style={{
											width: '100%',
											height: 200,
											objectFit: 'fill',
											borderRadius: 5,
										}}
										src='https://i.gadgets360cdn.com/large/flipkart_big_shopping_utsav_flipkart_1728379791938.jpg'
										alt=''
									/>
								</div>
							</div>
							<div className='col-lg-4 d-md-none d-lg-flex'>
								<div className='banner-img wow fadeIn animated  mb-sm-0'>
									<img
										style={{
											width: '100%',
											height: 200,
											objectFit: 'fill',
											borderRadius: 5,
										}}
										src='https://www.shutterstock.com/image-vector/flash-sale-event-big-offer-600nw-2473821577.jpg'
										alt=''
									/>
								</div>
							</div> */}
						</div>
					</div>
				</section>
				<NewArrivalsCarousel />
				<section className='deals section-padding'>
					<div className='container'>
						<div className='row'>
							{bottom?.result?.map((i) => {
								return (
									<div className='col-lg-6 deal-co'>
										<div
											className='deal wow fadeIn animated mb-md-4 mb-sm-4 mb-lg-0'
											style={{
												backgroundImage: `url(
											${i.imgpath}
										)`,
												// backgroundSize: 'contain', // Scales the background proportionally
												backgroundRepeat: 'no-repeat', // Prevents repeating the background image
												backgroundPosition: 'center', // Centers the image within the container
												width: '100%', // Adjust the width as needed
												height: 300, // Fixed height
												borderRadius: 5,
											}}></div>
									</div>
								);
							})}
						</div>
					</div>
				</section>
				{/* <section className='bg-grey-9 section-padding'>
					<div className='container pt-25 pb-25'>
						<div className='heading-tab d-flex'>
							<div className='heading-tab-left wow fadeIn animated'>
								<h3 className='section-title mb-20'>
									<span>Monthly</span> Best Sell
								</h3>
							</div>
							<div className='heading-tab-right wow fadeIn animated'>
								<ul
									className='nav nav-tabs right no-border'
									id='myTab-1'
									role='tablist'>
									<li className='nav-item' role='presentation'>
										<button
											className='nav-link active'
											id='nav-tab-one-1'
											data-bs-toggle='tab'
											data-bs-target='#tab-one-1'
											type='button'
											role='tab'
											aria-controls='tab-one'
											aria-selected='true'>
											Featured
										</button>
									</li>
									<li className='nav-item' role='presentation'>
										<button
											className='nav-link'
											id='nav-tab-two-1'
											data-bs-toggle='tab'
											data-bs-target='#tab-two-1'
											type='button'
											role='tab'
											aria-controls='tab-two'
											aria-selected='false'>
											Popular
										</button>
									</li>
									<li className='nav-item' role='presentation'>
										<button
											className='nav-link'
											id='nav-tab-three-1'
											data-bs-toggle='tab'
											data-bs-target='#tab-three-1'
											type='button'
											role='tab'
											aria-controls='tab-three'
											aria-selected='false'>
											New added
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-3 d-none d-lg-flex'>
								<div className='banner-img style-2 wow fadeIn animated'>
									<img src='assets/imgs/banner/banner-9.jpg' alt='' />
									<div className='banner-text'>
										<span>Woman Area</span>
										<h4 className='mt-5'>
											Save 17% on <br />
											Clothing
										</h4>
										<a href='shop-grid-right.html' className='text-white'>
											Shop Now <i className='fi-rs-arrow-right'></i>
										</a>
									</div>
								</div>
							</div>
							<div className='col-lg-9 col-md-12'>
								<MonthlyCarousel />
							</div>
						</div>
					</div>
				</section> */}
				{/* <section className='section-padding'>
					<div className='container pt-25 pb-20'>
						<div className='row'>
							<div className='col-lg-6'>
								<h3 className='section-title mb-20'>
									<span>From</span> blog
								</h3>
								<div className='post-list mb-4 mb-lg-0'>
									<article className='wow fadeIn animated'>
										<div className='d-md-flex d-block'>
											<div className='post-thumb d-flex mr-15'>
												<a
													className='color-white'
													href='blog-post-fullwidth.html'>
													<img src='assets/imgs/blog/blog-2.jpg' alt='' />
												</a>
											</div>
											<div className='post-content'>
												<div className='entry-meta mb-10 mt-10'>
													<a
														className='entry-meta meta-2'
														href='blog-category-fullwidth.html'>
														<span className='post-in font-x-small'>
															Fashion
														</span>
													</a>
												</div>
												<h4 className='post-title mb-25 text-limit-2-row'>
													<a href='blog-post-fullwidth.html'>
														Qualcomm is developing a Nintendo Switch-like
														console, report says
													</a>
												</h4>
												<div className='entry-meta meta-1 font-xs color-grey mt-10 pb-10'>
													<div>
														<span className='post-on'>14 April 2022</span>
														<span className='hit-count has-dot'>12M Views</span>
													</div>
													<a href='blog-post-right.html'>Read More</a>
												</div>
											</div>
										</div>
									</article>
									<article className='wow fadeIn animated'>
										<div className='d-md-flex d-block'>
											<div className='post-thumb d-flex mr-15'>
												<a
													className='color-white'
													href='blog-post-fullwidth.html'>
													<img src='assets/imgs/blog/blog-1.jpg' alt='' />
												</a>
											</div>
											<div className='post-content'>
												<div className='entry-meta mb-10 mt-10'>
													<a
														className='entry-meta meta-2'
														href='blog-category-fullwidth.html'>
														<span className='post-in font-x-small'>
															Healthy
														</span>
													</a>
												</div>
												<h4 className='post-title mb-25 text-limit-2-row'>
													<a href='blog-post-fullwidth.html'>
														Not even the coronavirus can derail 5G's global
														momentum
													</a>
												</h4>
												<div className='entry-meta meta-1 font-xs color-grey mt-10 pb-10'>
													<div>
														<span className='post-on'>14 April 2022</span>
														<span className='hit-count has-dot'>12M Views</span>
													</div>
													<a href='blog-post-right.html'>Read More</a>
												</div>
											</div>
										</div>
									</article>
								</div>
							</div>
							<div className='col-lg-6'>
								<div className='row'>
									<div className='col-md-6'>
										<div className='banner-img banner-1 wow fadeIn animated'>
											<img src='assets/imgs/banner/banner-5.jpg' alt='' />
											<div className='banner-text'>
												<span>Accessories</span>
												<h4>
													Save 17% on <br />
													Autumn Hat
												</h4>
												<a href='shop-grid-right.html'>
													Shop Now <i className='fi-rs-arrow-right'></i>
												</a>
											</div>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='banner-img mb-15 wow fadeIn animated'>
											<img src='assets/imgs/banner/banner-6.jpg' alt='' />
											<div className='banner-text'>
												<span>Big Offer</span>
												<h4>
													Save 20% on <br />
													Women's socks
												</h4>
												<a href='shop-grid-right.html'>
													Shop Now <i className='fi-rs-arrow-right'></i>
												</a>
											</div>
										</div>
										<div className='banner-img banner-2 wow fadeIn animated'>
											<img src='assets/imgs/banner/banner-7.jpg' alt='' />
											<div className='banner-text'>
												<span>Smart Offer</span>
												<h4>
													Save 20% on <br />
													Eardrop
												</h4>
												<a href='shop-grid-right.html'>
													Shop Now <i className='fi-rs-arrow-right'></i>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				<section className='mb-50'>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<div
									className='banner-bg wow fadeIn animated'
									style={{
										backgroundImage: `url(${single?.result[1]?.imgpath})`,
										backgroundRepeat: 'no-repeat', // Prevents repeating the background image
										backgroundPosition: 'center', // Centers the image within the container
										width: '100%', // Adjust the width as needed
										height: 200, // Fixed height
										borderRadius: 5,
									}}>
									{/* <div className='banner-content'>
										<h5 className='text-grey-4 mb-15'>Shop Today’s Deals</h5>
										<h2 className='fw-600'>
											Happy <span className='text-brand'>Mother's Day</span>.
											Big Sale Up to 40%
										</h2>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <section className='mb-45'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-3 col-md-6 mb-sm-5 mb-md-0'>
								<div className='banner-img wow fadeIn animated mb-md-4 mb-lg-0'>
									<img src='assets/imgs/banner/banner-10.jpg' alt='' />
									<div className='banner-text'>
										<span>Shoes Zone</span>
										<h4>
											Save 17% on <br />
											All Items
										</h4>
										<a href='shop-grid-right.html'>
											Shop Now <i className='fi-rs-arrow-right'></i>
										</a>
									</div>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 mb-sm-5 mb-md-0'>
								<h4 className='section-title style-1 mb-30 wow fadeIn animated'>
									Deals & Outlet
								</h4>
								<div className='product-list-small wow fadeIn animated'>
									<article className='row align-items-center'>
										<figure className='col-md-4 mb-0'>
											<a href='shop-product-right.html'>
												<img src='assets/imgs/shop/thumbnail-3.jpg' alt='' />
											</a>
										</figure>
										<div className='col-md-8 mb-0'>
											<h4 className='title-small'>
												<a href='shop-product-right.html'>
													Fish Print Patched T-shirt
												</a>
											</h4>
											<div className='product-price'>
												<span>$238.85 </span>
												<span className='old-price'>$245.8</span>
											</div>
										</div>
									</article>
									<article className='row align-items-center'>
										<figure className='col-md-4 mb-0'>
											<a href='shop-product-right.html'>
												<img src='assets/imgs/shop/thumbnail-4.jpg' alt='' />
											</a>
										</figure>
										<div className='col-md-8 mb-0'>
											<h4 className='title-small'>
												<a href='shop-product-right.html'>
													Vintage Floral Print Dress
												</a>
											</h4>
											<div className='product-price'>
												<span>$238.85 </span>
												<span className='old-price'>$245.8</span>
											</div>
										</div>
									</article>
									<article className='row align-items-center'>
										<figure className='col-md-4 mb-0'>
											<a href='shop-product-right.html'>
												<img src='assets/imgs/shop/thumbnail-5.jpg' alt='' />
											</a>
										</figure>
										<div className='col-md-8 mb-0'>
											<h4 className='title-small'>
												<a href='shop-product-right.html'>
													Multi-color Stripe Circle Print T-Shirt
												</a>
											</h4>
											<div className='product-price'>
												<span>$238.85 </span>
												<span className='old-price'>$245.8</span>
											</div>
										</div>
									</article>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 mb-sm-5 mb-md-0'>
								<h4 className='section-title style-1 mb-30 wow fadeIn animated'>
									Top Selling
								</h4>
								<div className='product-list-small wow fadeIn animated'>
									<article className='row align-items-center'>
										<figure className='col-md-4 mb-0'>
											<a href='shop-product-right.html'>
												<img src='assets/imgs/shop/thumbnail-6.jpg' alt='' />
											</a>
										</figure>
										<div className='col-md-8 mb-0'>
											<h4 className='title-small'>
												<a href='shop-product-right.html'>
													Geometric Printed Long Sleeve Blosue
												</a>
											</h4>
											<div className='product-price'>
												<span>$238.85 </span>
												<span className='old-price'>$245.8</span>
											</div>
										</div>
									</article>
									<article className='row align-items-center'>
										<figure className='col-md-4 mb-0'>
											<a href='shop-product-right.html'>
												<img src='assets/imgs/shop/thumbnail-7.jpg' alt='' />
											</a>
										</figure>
										<div className='col-md-8 mb-0'>
											<h4 className='title-small'>
												<a href='shop-product-right.html'>
													Print Patchwork Maxi Dress
												</a>
											</h4>
											<div className='product-price'>
												<span>$238.85 </span>
												<span className='old-price'>$245.8</span>
											</div>
										</div>
									</article>
									<article className='row align-items-center'>
										<figure className='col-md-4 mb-0'>
											<a href='shop-product-right.html'>
												<img src='assets/imgs/shop/thumbnail-8.jpg' alt='' />
											</a>
										</figure>
										<div className='col-md-8 mb-0'>
											<h4 className='title-small'>
												<a href='shop-product-right.html'>
													Daisy Floral Print Straps Jumpsuit
												</a>
											</h4>
											<div className='product-price'>
												<span>$238.85 </span>
												<span className='old-price'>$245.8</span>
											</div>
										</div>
									</article>
								</div>
							</div>
							<div className='col-lg-3 col-md-6'>
								<h4 className='section-title style-1 mb-30 wow fadeIn animated'>
									Hot Releases
								</h4>
								<div className='product-list-small wow fadeIn animated'>
									<article className='row align-items-center'>
										<figure className='col-md-4 mb-0'>
											<a href='shop-product-right.html'>
												<img src='assets/imgs/shop/thumbnail-9.jpg' alt='' />
											</a>
										</figure>
										<div className='col-md-8 mb-0'>
											<h4 className='title-small'>
												<a href='shop-product-right.html'>
													Floral Print Casual Cotton Dress
												</a>
											</h4>
											<div className='product-price'>
												<span>$238.85 </span>
												<span className='old-price'>$245.8</span>
											</div>
										</div>
									</article>
									<article className='row align-items-center'>
										<figure className='col-md-4 mb-0'>
											<a href='shop-product-right.html'>
												<img src='assets/imgs/shop/thumbnail-1.jpg' alt='' />
											</a>
										</figure>
										<div className='col-md-8 mb-0'>
											<h4 className='title-small'>
												<a href='shop-product-right.html'>
													Ruffled Solid Long Sleeve Blouse
												</a>
											</h4>
											<div className='product-price'>
												<span>$238.85 </span>
												<span className='old-price'>$245.8</span>
											</div>
										</div>
									</article>
									<article className='row align-items-center'>
										<figure className='col-md-4 mb-0'>
											<a href='shop-product-right.html'>
												<img src='assets/imgs/shop/thumbnail-2.jpg' alt='' />
											</a>
										</figure>
										<div className='col-md-8 mb-0'>
											<h4 className='title-small'>
												<a href='shop-product-right.html'>
													Multi-color Print V-neck T-Shirt
												</a>
											</h4>
											<div className='product-price'>
												<span>$238.85 </span>
												<span className='old-price'>$245.8</span>
											</div>
										</div>
									</article>
								</div>
							</div>
						</div>
					</div>
				</section> */}
			</main>
		</>
	);
};

export default MainPart;
