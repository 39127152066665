import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import bag from '../../assets/images/bag.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { addWhitelistRow, addCartRow } from '../../reducers/UiReducer';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useMediaQuery, useTheme } from '@mui/material';

const NewArrivalsCarousel = () => {
	const { product, productPremium, loginInfo, markup } = useSelector(
		(state) => state.home
	);
	const { cart } = useSelector((state) => state.ui);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const products = [
		{
			id: 1,
			name: 'Lorem ipsum dolor',
			price: 238.85,
			oldPrice: 245.8,
			badge: 'Hot',
			img1: 'assets/imgs/shop/product-2-1.jpg',
			img2: 'assets/imgs/shop/product-2-2.jpg',
		},
		{
			id: 2,
			name: 'Aliquam posuere',
			price: 173.85,
			oldPrice: 185.8,
			badge: 'New',
			img1: 'assets/imgs/shop/product-4-1.jpg',
			img2: 'assets/imgs/shop/product-4-2.jpg',
		},
		{
			id: 3,
			name: 'Sed dapibus orci',
			price: 215.85,
			oldPrice: 235.8,
			badge: 'Sale',
			img1: 'assets/imgs/shop/product-15-1.jpg',
			img2: 'assets/imgs/shop/product-15-2.jpg',
		},
		{
			id: 4,
			name: 'Sed dapibus orci',
			price: 215.85,
			oldPrice: 235.8,
			badge: 'Sale',
			img1: 'assets/imgs/shop/product-15-1.jpg',
			img2: 'assets/imgs/shop/product-15-2.jpg',
		},
		{
			id: 5,
			name: 'Sed dapibus orci',
			price: 215.85,
			oldPrice: 235.8,
			badge: 'Sale',
			img1: 'assets/imgs/shop/product-15-1.jpg',
			img2: 'assets/imgs/shop/product-15-2.jpg',
		},
		{
			id: 6,
			name: 'Sed dapibus orci',
			price: 215.85,
			oldPrice: 235.8,
			badge: 'Sale',
			img1: 'assets/imgs/shop/product-15-1.jpg',
			img2: 'assets/imgs/shop/product-15-2.jpg',
		},
		// Add more products here
	];
	let data = [];
	if (product?.result?.length > 0 && productPremium?.result?.length > 0) {
		data = [...productPremium?.result, ...product?.result];
	}
	return (
		<section className='section-padding'>
			<div className='container wow fadeIn animated'>
				<h3 className='section-title mb-20'>
					<span>New</span> Arrivals
				</h3>
				<Swiper
					modules={[Navigation, Pagination, Autoplay]}
					navigation
					pagination={{ clickable: true }}
					spaceBetween={20}
					slidesPerView={matches ? 1 : 4}
					autoplay={{ delay: 1000, disableOnInteraction: false }}
					loop={true}
					breakpoints={{
						640: { slidesPerView: 2 },
						768: { slidesPerView: 3 },
						1024: { slidesPerView: 4 },
					}}>
					{data?.length > 0
						? data?.map((i) => {
								return (
									<SwiperSlide key={i.id}>
										<div className='product-cart-wrap small hover-up'>
											<div className='product-img-action-wrap'>
												<div className='product-img product-img-zoom'>
													<Link
														to={
															loginInfo?.status === 'success' &&
															loginInfo?.result?.status === '1'
																? `/productdetails/?name=${i.name}&id=${i.id}`
																: ''
														}>
														<a>
															<img
																className='default-img'
																src={i.imgpath}
																style={{
																	width: '100%',
																	// height: 300,
																	objectFit: 'fill',
																	borderRadius: 5,
																}}
																alt=''
															/>
															<img
																className='hover-img'
																src='assets/imgs/shop/product-1-2.jpg'
																alt=''
															/>
														</a>
													</Link>
												</div>
												<div className='product-action-1'>
													{/* <a
																																											aria-label='View'
																																											className='action-btn hover-up'
																																											data-bs-toggle='modal'
																																											data-bs-target='#quickViewModal'>
																																											<i className='fi-rs-eye'></i>
																																										</a> */}
													<a
														onClick={() => {
															if (
																loginInfo?.status === 'success' &&
																loginInfo?.result?.status === '1'
															) {
																dispatch(addWhitelistRow(i));
																navigate('/whishlist');
															}
														}}
														aria-label='Add To Wishlist'
														className='action-btn hover-up'>
														<i className='fi-rs-heart'></i>
													</a>
													{/* <a
																																									aria-label='Compare'
																																									className='action-btn hover-up'
																																									href='shop-compare.html'>
																																									<i className='fi-rs-shuffle'></i>
																																								</a> */}
												</div>
												{i.total_quantity < i.min_order ? (
													<div className='product-badges product-badges-position product-badges-mrg'>
														<span className='hot'>OUT OF STOCK</span>
													</div>
												) : (
													''
												)}
											</div>
											<div className='product-content-wrap'>
												<div className='product-category'>
													<a href='shop-grid-right.html'>{i.category?.name}</a>
												</div>
												<h2>{i.name}</h2>
												{/* <div className='rating-result' title='90%'>
																																										<span>
																																											<span>90%</span>
																																										</span>
																																									</div> */}
												<div className='product-price'>
													{loginInfo?.status === 'success' &&
														loginInfo?.result?.status === '1' && (
															<span>
																₹
																{Number(
																	Math.round(
																		Number(i.price_per) +
																			Number(
																				Number(
																					loginInfo?.result?.role ===
																						'Credit' || 'Premium'
																						? i.price_per
																						: 0
																				) *
																					Number(
																						parseInt(
																							loginInfo?.result?.role ===
																								'Credit'
																								? markup?.result[0]?.markup
																								: markup?.result[0]
																										?.markup_premium
																						)
																					)
																			) /
																				100
																	)
																)}
															</span>
														)}
													{/* <span className='old-price'>$245.8</span> */}
												</div>
												<div
													className='product-action-1 show'
													onClick={() => {
														if (i.total_quantity < i.min_order) {
															Swal.fire({
																title: 'OUT OF STOCK',
																icon: 'info',
																showCloseButton: true,
																showCancelButton: true,
																focusConfirm: false,
																confirmButtonAriaLabel: 'Thumbs up, great!',
																cancelButtonAriaLabel: 'Thumbs down',
															});
														} else {
															if (
																loginInfo?.status === 'success' &&
																loginInfo?.result?.status === '1'
															) {
																const exists = cart?.some(
																	(obj) => obj.id === i.id
																);
																if (exists) {
																	navigate('/cart');
																} else {
																	dispatch(addCartRow(i));
																	Swal.fire({
																		position: 'top-end',
																		icon: 'success',
																		title: 'Added To Cart',
																		text: 'Product has been added to your cart!',
																		showConfirmButton: false,
																		timer: 1500,
																	});
																}
															}
														}
													}}>
													<a
														aria-label={
															cart?.some((obj) => obj.id === i.id)
																? 'Go To Cart'
																: 'Add To Cart'
														}
														className='action-btn hover-up'>
														<i className='fi-rs-shopping-bag-add'></i>
													</a>
												</div>
											</div>
										</div>
									</SwiperSlide>
								);
						  })
						: product?.result?.map((i) => (
								<SwiperSlide key={i.id}>
									<div className='product-cart-wrap small hover-up'>
										<div className='product-img-action-wrap'>
											<div className='product-img product-img-zoom'>
												<Link
													to={
														loginInfo?.status === 'success' &&
														loginInfo?.result?.status === '1'
															? `/productdetails/?name=${i.name}&id=${i.id}`
															: '/login'
													}>
													<a>
														<img
															className='default-img'
															src={i.imgpath}
															style={{
																width: '100%',
																height: 400,
																// objectFit: 'fill',
																borderRadius: 5,
															}}
															alt=''
														/>
														<img
															className='hover-img'
															src='assets/imgs/shop/product-1-2.jpg'
															alt=''
														/>
													</a>
												</Link>
											</div>
											<div className='product-action-1'>
												{/* <a
																																									aria-label='View'
																																									className='action-btn hover-up'
																																									data-bs-toggle='modal'
																																									data-bs-target='#quickViewModal'>
																																									<i className='fi-rs-eye'></i>
																																								</a> */}
												<a
													onClick={() => {
														if (
															loginInfo?.status === 'success' &&
															loginInfo?.result?.status === '1'
														) {
															dispatch(addWhitelistRow(i));
															navigate('/whishlist');
														}
													}}
													aria-label='Add To Wishlist'
													className='action-btn hover-up'>
													<i className='fi-rs-heart'></i>
												</a>
												{/* <a
																																							aria-label='Compare'
																																							className='action-btn hover-up'
																																							href='shop-compare.html'>
																																							<i className='fi-rs-shuffle'></i>
																																						</a> */}
											</div>
											{i.total_quantity < i.min_order ? (
												<div className='product-badges product-badges-position product-badges-mrg'>
													<span className='hot'>OUT OF STOCK</span>
												</div>
											) : (
												''
											)}
										</div>
										<div className='product-content-wrap'>
											<div className='product-category'>
												<a href='shop-grid-right.html'>{i.category?.name}</a>
											</div>
											<h2>{i.name}</h2>
											{/* <div className='rating-result' title='90%'>
																																								<span>
																																									<span>90%</span>
																																								</span>
																																							</div> */}
											<div className='product-price'>
												{loginInfo?.status === 'success' &&
													loginInfo?.result?.status === '1' && (
														<span>
															₹
															{Number(
																Math.round(
																	Number(i.price_per) +
																		Number(
																			Number(
																				loginInfo?.result?.role === 'Credit'
																					? i.price_per
																					: 0
																			) *
																				Number(
																					parseInt(markup?.result[0]?.markup)
																				)
																		) /
																			100
																)
															)}
														</span>
													)}
												{/* <span className='old-price'>$245.8</span> */}
											</div>
											<div
												className='product-action-1 show'
												onClick={() => {
													if (i.total_quantity < i.min_order) {
														Swal.fire({
															title: 'OUT OF STOCK',
															icon: 'info',
															showCloseButton: true,
															showCancelButton: true,
															focusConfirm: false,
															confirmButtonAriaLabel: 'Thumbs up, great!',
															cancelButtonAriaLabel: 'Thumbs down',
														});
													} else {
														if (
															loginInfo?.status === 'success' &&
															loginInfo?.result?.status === '1'
														) {
															const exists = cart?.some(
																(obj) => obj.id === i.id
															);
															if (exists) {
																navigate('/cart');
															} else {
																dispatch(addCartRow(i));
																Swal.fire({
																	position: 'top-end',
																	icon: 'success',
																	title: 'Added To Cart',
																	text: 'Product has been added to your cart!',
																	showConfirmButton: false,
																	timer: 1500,
																});
															}
														} else {
															navigate('/login');
														}
													}
												}}>
												<a
													aria-label={
														cart?.some((obj) => obj.id === i.id)
															? 'Go To Cart'
															: 'Add To Cart'
													}
													className='action-btn hover-up'>
													<i className='fi-rs-shopping-bag-add'></i>
												</a>
											</div>
										</div>
									</div>
								</SwiperSlide>
						  ))}
				</Swiper>
			</div>
		</section>
	);
};

export default NewArrivalsCarousel;
