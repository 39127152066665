import React from 'react';
import {
	Badge,
	Button,
	Card,
	Drawer,
	Icon,
	IconButton,
	ThemeProvider,
	Box,
	styled,
	useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNotificationDrawerModal } from '../../reducers/UiReducer';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { differenceInSeconds } from 'date-fns';
import { deltenotificationDispatch } from '../../reducers/HomeReducer';
import ClearIcon from '@mui/icons-material/Clear';
import { Link } from 'react-router-dom';
const Notification = styled('div')(() => ({
	padding: '16px',
	marginBottom: '16px',
	display: 'flex',
	alignItems: 'center',
	height: 64,
	boxShadow:
		'0px 3px 5px -1px rgba(0, 0, 0, 0.06),0px 5px 8px 0px rgba(0, 0, 0, 0.042),0px 1px 14px 0px rgba(0, 0, 0, 0.036)',
	'& h5': {
		marginLeft: '8px',
		marginTop: 0,
		marginBottom: 0,
		fontWeight: '500',
	},
}));

const NotificationCard = styled(Box)(({ theme }) => ({
	position: 'relative',
	'&:hover': {
		'& .messageTime': {
			display: 'none',
		},
		'& .deleteButton': {
			opacity: '1',
		},
	},
	// '& .messageTime': {
	// 	color: theme.palette.text.secondary,
	// },
	'& .icon': { fontSize: '1.25rem' },
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
	opacity: '0',
	position: 'absolute',
	right: 5,
	marginTop: 9,
	marginRight: '24px',
	background: 'rgba(0, 0, 0, 0.01)',
}));
const CardLeftContent = styled('div')(({ theme }) => ({
	padding: '12px 8px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	background: 'rgba(0, 0, 0, 0.01)',
	'& small': {
		fontWeight: '500',
		marginLeft: '16px',
		// color: theme.palette.text.secondary,
	},
}));

const Heading = styled('span')(({ theme }) => ({
	fontWeight: '500',
	marginLeft: '16px',
	marginRight: '16px',
	fontSize: 12,
	// color: theme.palette.text.secondary,
}));
const BasicDrawer = () => {
	const dispatch = useDispatch();
	const { drawerNotificationmodal } = useSelector((state) => state.ui);
	const { notify, loginInfo } = useSelector((state) => state.home);
	function getTimeDifference(date) {
		let difference = differenceInSeconds(new Date(), date);

		if (difference < 60) return `${Math.floor(difference)} sec`;
		else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
		else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
		else if (difference < 86400 * 30)
			return `${Math.floor(difference / 86400)} d`;
		else if (difference < 86400 * 30 * 12)
			return `${Math.floor(difference / 86400 / 30)} mon`;
		else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
	}
	return (
		<>
			<Drawer
				width={'100px'}
				// container={container}
				variant='temporary'
				anchor={'right'}
				open={drawerNotificationmodal}
				onClose={() => dispatch(toggleNotificationDrawerModal(false))}
				ModalProps={{
					keepMounted: true,
				}}>
				<Box sx={{ width: 260 }}>
					<Notification>
						<NotificationsIcon style={{ fontSize: 30 }} />
						<h5>Notifications</h5>
					</Notification>

					{notify?.data?.map((notification) => (
						<NotificationCard key={notification.id}>
							<DeleteButton
								size='small'
								className='deleteButton'
								onClick={() =>
									dispatch(
										deltenotificationDispatch(
											notification.id,
											loginInfo?.result?.id
										)
									)
								}>
								<ClearIcon />
							</DeleteButton>
							<Link
								to={`/dashboard`}
								// onClick={() => handleDrawerToggle(notification.id)}
								style={{ textDecoration: 'none' }}>
								<Card sx={{ mx: 2, mb: 3 }} elevation={3}>
									<CardLeftContent>
										<Box display='flex' alignItems={'center'}>
											<NotificationsIcon style={{ fontSize: 16 }} />
											<Heading>{`${notification.desc}`}</Heading>
										</Box>
										<span className='messageTime'>
											{getTimeDifference(new Date(notification.created_at))}
											ago
										</span>
									</CardLeftContent>
									<Box sx={{ px: 2, pt: 1, pb: 2 }}>
										{/* <Paragraph sx={{ m: 0 }}>{notification.title}</Paragraph>
											<Small sx={{ color: secondary }}>
												{notification.subtitle}
											</Small> */}
									</Box>
								</Card>
							</Link>
						</NotificationCard>
					))}
					{/* {!!notify?.data?.length && (
						<Box sx={{ color: 'grey' }}>
							<Button>Clear Notifications</Button>
						</Box>
					)} */}
				</Box>
			</Drawer>
		</>
	);
};

export default BasicDrawer;
