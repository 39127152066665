import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordCustomerDispatch } from '../reducers/HomeReducer';
import Swal from 'sweetalert2';

const ChangePassword = () => {
	const { loginInfo } = useSelector((state) => state.home);
	const dispatch = useDispatch();

	// Initial Values
	const initialValues = {
		password: '',
		password_confirmation: '',
	};

	// Validation Schema
	const validationSchema = Yup.object({
		password: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
			.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
			.matches(/[0-9]/, 'Password must contain at least one number')
			.matches(
				/[@$!%*?&]/,
				'Password must contain at least one special character'
			)
			.required('Password is required'),
		password_confirmation: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
			.required('Password confirmation is required'),
	});

	// Form Submission
	const handleSubmit = (values) => {
		console.log('Form Data', values);
		const payload = {
			id: loginInfo?.result?.id,
			password: values.password,
			password_confirmation: values.password_confirmation,
		};
		dispatch(changePasswordCustomerDispatch(payload, Swal));
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}>
			{() => (
				<Form style={{ padding: 20 }}>
					<div className='form-group'>
						<label>New Password *</label>
						<Field name='password' type='password' className='form-control' />
						<ErrorMessage name='password' component='div' className='error' />
					</div>

					<div className='form-group'>
						<label>Confirm Password *</label>
						<Field
							name='password_confirmation'
							type='password'
							className='form-control'
						/>
						<ErrorMessage
							name='password_confirmation'
							component='div'
							className='error'
						/>
					</div>

					<button type='submit' className='btn btn-primary'>
						Change Password
					</button>
				</Form>
			)}
		</Formik>
	);
};

export default ChangePassword;
