import React from 'react';
import {
	Drawer,
	Box,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/logo.webp';
import {
	clearCartRow,
	clearWhislistRow,
	toggleDrawerModal,
} from '../../reducers/UiReducer';
import { Link, useNavigate } from 'react-router-dom';
import {
	loginSuccess,
	productPremiumDispatch,
} from '../../reducers/HomeReducer';
const data = ['Home', 'About', 'Product', 'Dashboard', 'Logout'];
const data2 = ['Home', 'About', 'Product', 'Login/Signup'];
const Drawers = () => {
	const { drawermodal } = useSelector((state) => state.ui);
	const { loginInfo, customerDetail } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	return (
		<>
			<Drawer
				width={'100px'}
				variant='temporary'
				anchor={'right'}
				open={drawermodal}
				onClose={() => dispatch(toggleDrawerModal(false))}
				ModalProps={{
					keepMounted: true,
				}}>
				<Box sx={{ width: 260, p: 2 }}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							mb: 2,
						}}>
						<Box
							component='img'
							src={logo}
							alt='Logo'
							sx={{ height: 80, width: 80, mr: 1 }}
						/>
					</Box>
					<Box>
						<p>Hi {loginInfo?.result?.firstname}</p>
						<p>
							{loginInfo?.status === 'success' &&
								loginInfo?.result?.status === '1' && (
									<>
										<i className='fi-rs-wallet'></i>
										<Link to='/dashboard'>Wallet</Link>
										<span
											className='wallet-balance'
											style={{ marginLeft: 5, fontWeight: 'bold' }}>
											{`₹${customerDetail?.result?.wallet}`}
											{customerDetail?.result?.odwallet > 0
												? `(-${
														customerDetail?.result?.odwallet > 0
															? customerDetail?.result?.odwallet
															: ''
												  })`
												: ''}
										</span>
									</>
								)}
						</p>
					</Box>
					<List>
						{loginInfo?.token
							? data.map((text) => (
									<ListItem
										button
										key={text}
										onClick={() => {
											navigate(
												text === 'Dashboard'
													? '/dashboard'
													: text === 'Product'
													? '/product'
													: '/'
											);
											dispatch(toggleDrawerModal(false));
											if (text === 'Logout') {
												navigate('/');
												localStorage.removeItem('loginInfo');
												localStorage.removeItem('customerDetail');
												localStorage.removeItem('cart');
												localStorage.removeItem('whishlist');
												dispatch(loginSuccess(null));
												dispatch(productPremiumDispatch(null));
												dispatch(clearCartRow());
												dispatch(clearWhislistRow());
											}
										}}
										// onClick={() => handleNavigation(text.toLowerCase())}
									>
										<ListItemText primary={text} />
									</ListItem>
							  ))
							: data2.map((text) => (
									<ListItem
										button
										key={text}
										onClick={() => {
											navigate(
												text === 'Login/Signup'
													? '/login'
													: text === 'Product'
													? '/product'
													: '/'
											);
											dispatch(toggleDrawerModal(false));
										}}
										// onClick={() => handleNavigation(text.toLowerCase())}
									>
										<ListItemText primary={text} />
									</ListItem>
							  ))}
					</List>
				</Box>
			</Drawer>
		</>
	);
};

export default Drawers;
