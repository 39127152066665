import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import LoginPage from '../pages/LoginPage';
import ProductDetailsPage from '../pages/ProductDetailsPage';
import { CartPage } from '../pages/CartPage';
import WhislistPage from '../pages/WhislistPage';
import MyAccount from '../pages/MyAccount';
import ContactPage from '../pages/ContactPage';
import CheckoutPage from '../pages/CheckoutPage';
import NotFoundPage from '../pages/NotFoundPage';
import OrderPlaced from '../pages/OrderPlaced';
import ProductList from '../pages/ProductList';
import BankDetails from '../pages/BankDetails';
import VerifyUser from '../pages/VerifyUser';
import ResetUser from '../pages/ResetUser';
import OrderPage from './resuableComponents/OrderPage';
import { useDispatch, useSelector } from 'react-redux';
import { customeridDispatch, loggedDispatch } from '../reducers/HomeReducer';

const MainRouter = () => {
	const dispatch = useDispatch();
	const { loginInfo } = useSelector((state) => state.home);
	useEffect(() => {
		if (loginInfo?.token) {
			let form = {
				token: loginInfo?.token,
			};
			dispatch(loggedDispatch(form));
			dispatch(customeridDispatch(loginInfo?.result?.id));
		}
	}, []);
	return (
		<div>
			<Routes>
				<Route path='/' exact element={<LandingPage />} />
				<Route path='/login' exact element={<LoginPage />} />
				<Route path='/productdetails' exact element={<ProductDetailsPage />} />
				<Route path='/product' exact element={<ProductList />} />
				<Route path='/cart' exact element={<CartPage />} />
				<Route path='/whishlist' exact element={<WhislistPage />} />
				<Route path='/dashboard' exact element={<MyAccount />} />
				<Route path='/contact' exact element={<ContactPage />} />
				<Route path='/about' exact element={<ContactPage />} />
				<Route path='/termsandservice' exact element={<ContactPage />} />
				<Route path='/privacy' exact element={<ContactPage />} />
				<Route path='/purchaseguide' exact element={<ContactPage />} />
				<Route path='/checkout' exact element={<CheckoutPage />} />
				<Route path='/order' exact element={<OrderPlaced />} />
				<Route path='/invoice' exact element={<OrderPage />} />
				<Route path='/verify' exact element={<VerifyUser />} />
				<Route path='/reset' exact element={<ResetUser />} />
				<Route path='/bankdetails' exact element={<BankDetails />} />
				<Route path='*' exact element={<NotFoundPage />} />
			</Routes>
		</div>
	);
};

export default MainRouter;
