import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomerDispatch } from '../reducers/HomeReducer';
import Swal from 'sweetalert2';

const EditProfile = () => {
	const { loginInfo } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const validationSchema = Yup.object({
		firstname: Yup.string().required('First Name is required'),
		lastname: Yup.string().required('Last Name is required'),
		phone: Yup.string().required('Phone is required'),
		panNumber: Yup.string().required('panNumber Name is required'),
		gstInNumber: Yup.string().required('gstInNumber Name is required'),
		city: Yup.string().required('city  is required'),
		state: Yup.string().required('state  is required'),
		country: Yup.string().required('country  is required'),
		pincode: Yup.string().required('pincode  is required'),
		email: Yup.string()
			.email('Invalid email address')
			.required('Email Address is required'),
	});

	return (
		<Formik
			initialValues={{
				firstname: loginInfo?.result?.firstname,
				lastname: loginInfo?.result?.lastname,
				contact: loginInfo?.result?.contact,
				email: loginInfo?.result?.email,
				panNumber: loginInfo?.result?.panNumber,
				gstInNumber: loginInfo?.result?.gstInNumber,
				city: loginInfo?.result?.city,
				state: loginInfo?.result?.state,
				country: loginInfo?.result?.country,
				address: loginInfo?.result?.address,
				pincode: loginInfo?.result?.pincode,
			}}
			enableReinitialize={true}
			// validationSchema={validationSchema}
			onSubmit={(values) => {
				// Handle form submission logic here
				console.log(values);
				dispatch(updateCustomerDispatch(values, loginInfo?.result?.id, Swal));
			}}>
			{() => (
				<Form name='enq'>
					<div className='row'>
						<div className='form-group col-md-6'>
							<label>
								First Name <span className='required'></span>
							</label>
							<Field
								className='form-control square'
								name='firstname'
								type='text'
							/>
							<ErrorMessage
								name='firstname'
								component='div'
								className='error'
							/>
						</div>

						<div className='form-group col-md-6'>
							<label>
								Last Name <span className='required'></span>
							</label>
							<Field
								className='form-control square'
								name='lastname'
								type='text'
							/>
							<ErrorMessage name='lastname' component='div' className='error' />
						</div>

						<div className='form-group col-md-12'>
							<label>
								Contact <span className='required'></span>
							</label>
							<Field
								className='form-control square'
								name='contact'
								type='text'
							/>
							<ErrorMessage name='contact' component='div' className='error' />
						</div>

						<div className='form-group col-md-12'>
							<label>
								Email Address <span className='required'></span>
							</label>
							<Field
								className='form-control square'
								name='email'
								type='email'
							/>
							<ErrorMessage name='email' component='div' className='error' />
						</div>

						<div className='form-group col-md-12'>
							<label>
								Pan Number<span className='required'></span>
							</label>
							<Field
								className='form-control square'
								name='panNumber'
								type='text'
							/>
							<ErrorMessage
								name='panNumber'
								component='div'
								className='error'
							/>
						</div>

						<div className='form-group col-md-12'>
							<label>
								GSTIN Number <span className='required'></span>
							</label>
							<Field
								className='form-control square'
								name='gstInNumber'
								type='text'
							/>
							<ErrorMessage
								name='gstInNumber'
								component='div'
								className='error'
							/>
						</div>
						<div className='form-group col-md-12'>
							<label>
								City <span className='required'>*</span>
							</label>
							<Field className='form-control square' name='city' type='text' />
							<ErrorMessage name='city' component='div' className='error' />
						</div>
						<div className='form-group col-md-12'>
							<label>
								State <span className='required'></span>
							</label>
							<Field className='form-control square' name='state' type='text' />
							<ErrorMessage name='state' component='div' className='error' />
						</div>
						<div className='form-group col-md-12'>
							<label>
								Country <span className='required'></span>
							</label>
							<Field
								className='form-control square'
								name='country'
								type='text'
							/>
							<ErrorMessage name='country' component='div' className='error' />
						</div>
						<div className='form-group col-md-12'>
							<label>
								Address <span className='required'></span>
							</label>
							<Field
								className='form-control square'
								name='address'
								type='text'
							/>
							<ErrorMessage name='address' component='div' className='error' />
						</div>
						<div className='form-group col-md-12'>
							<label>
								Pin Code <span className='required'></span>
							</label>
							<Field
								className='form-control square'
								name='pincode'
								type='text'
							/>
							<ErrorMessage name='pincode' component='div' className='error' />
						</div>
						<div className='col-md-12'>
							<button type='submit' className='btn btn-fill-out submit'>
								Save
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default EditProfile;
