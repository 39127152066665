import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import bag from '../../assets/images/bag.jpg';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ProductGallery() {
	const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
	const { productDetail } = useSelector((state) => state.home);
	const location = useLocation();
	console.log('location', location);

	return (
		<div className='detail-gallery'>
			<span className='zoom-icon'>
				<i className='fi-rs-search'></i>
			</span>
			{/* MAIN SLIDES */}
			<Swiper
				modules={[Navigation, Thumbs]}
				navigation
				thumbs={{ swiper: thumbsSwiper }}
				className='product-image-slider'>
				{JSON?.parse(productDetail?.result[0]?.multipleimage)?.map((i) => {
					return (
						<SwiperSlide>
							<figure className='border-radius-10'>
								<img src={i} alt='product image' />
							</figure>
						</SwiperSlide>
					);
				})}

				{/* <SwiperSlide>
					<figure className='border-radius-10'>
						<img src='assets/imgs/shop/product-16-1.jpg' alt='product image' />
					</figure>
				</SwiperSlide>
				<SwiperSlide>
					<figure className='border-radius-10'>
						<img src='assets/imgs/shop/product-16-3.jpg' alt='product image' />
					</figure>
				</SwiperSlide> */}
				{/* Add more slides as needed */}
			</Swiper>

			{/* THUMBNAILS */}
			<Swiper
				modules={[Thumbs]}
				onSwiper={setThumbsSwiper}
				spaceBetween={10}
				slidesPerView={4}
				freeMode
				watchSlidesProgress
				className='slider-nav-thumbnails'>
				{JSON.parse(productDetail?.result[0]?.multipleimage)?.map((i) => {
					return (
						<SwiperSlide>
							<img src={i} alt='product thumbnail' />
						</SwiperSlide>
					);
				})}

				{/* Add more thumbnail slides as needed */}
			</Swiper>
		</div>
	);
}
