import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function PaymentHistory() {
	const { product, loginInfo, customer, custTrans, payment } = useSelector(
		(state) => state.home
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const columns = [
		{ field: 'id', headerName: 'SNo.', width: 100 },
		{
			field: 'transId',
			headerName: 'Transaction ID',
			width: 200,
		},
		{
			field: 'amount',
			headerName: 'Amount',
			width: 200,
		},
		{
			field: 'status',
			headerName: 'status',
			width: 150,
			// renderCell: (cellValues) => {
			// 	return (
			// 		<Box
			// 			style={{ display: 'flex', justifyContent: 'space-between' }}
			// 			mt={2}>
			// 			{cellValues.row.status === 'paid' ? (
			// 				<span className='badge bg-success-transparent'>Paid</span>
			// 			) : cellValues.row.status === 'confirmed' ? (
			// 				<span className='badge bg-success-transparent'>Confirmed</span>
			// 			) : cellValues.row.status === 'pending' ? (
			// 				<span className='badge bg-primary '>Pending</span>
			// 			) : (
			// 				<span class='badge bg-danger'>Rejected</span>
			// 			)}
			// 		</Box>
			// 	);
			// },
		},
		{
			field: 'payment_type',
			headerName: 'Payment Type',
			width: 200,
		},
		{
			field: 'imgpath',
			headerName: 'Payment Screenshot',
			width: 300,
			renderCell: (cellValues) => (
				<Box style={{ display: 'flex', justifyContent: 'center' }}>
					<img
						src={cellValues.row.imgpath}
						style={{
							height: 50,
							width: 50,
							cursor: 'pointer',
							borderRadius: '5px',
						}}
						alt='Payment Screenshot'
						// onClick={() => handleImageClick(cellValues.row.imgpath)}
					/>
				</Box>
			),
		},
		{
			field: 'created_at',
			headerName: 'Date',
			width: 300,
			editable: true,
			renderCell: (cellValues) => {
				return (
					<Box mt={2}>
						<p style={{ fontSize: 14 }}>{`${
							cellValues.row.created_at.split('T')[0].split('-')[2]
						}-${cellValues.row.created_at.split('T')[0].split('-')[1]}-${
							cellValues.row.created_at.split('T')[0].split('-')[0]
						} / ${cellValues.row.created_at.split('T')[1].split('.')[0]}`}</p>
					</Box>
				);
			},
		},
	];
	const handleClick = (event, cellValues) => {};
	const handleClickDelete = (event, cellValues) => {
		// dispatch(deleteproductDispatch(cellValues.id, loginInfo.token));
	};
	let data = [];
	if (payment?.result?.length > 0) {
		payment?.result?.map((i) => {
			if (i.cust_id === loginInfo?.result?.id) {
				data.push({
					...i,
				});
			}
		});
	}
	return (
		<Box sx={{ height: 400, width: '100%' }}>
			{data.length > 0 ? (
				<DataGrid
					sx={{
						'&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
							outline: 'none',
						},
						'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
							outline: 'none',
						},
						// Custom scrollbar styles
						'& .MuiDataGrid-virtualScroller': {
							'&::-webkit-scrollbar': {
								height: '8px',
								backgroundColor: '#f5f5f5',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#888',
								borderRadius: '4px',
							},
							'&::-webkit-scrollbar-thumb:hover': {
								backgroundColor: '#555',
							},
						},
					}}
					rows={data}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					disableRowSelectionOnClick
				/>
			) : (
				<h3 style={{ fontSize: 20 }}>No Record Found</h3>
			)}
		</Box>
	);
}
