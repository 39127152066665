import { createSlice } from '@reduxjs/toolkit';
export const storeData = async (key, value) => {
	try {
		const now = new Date();
		const dataWithExpiry = {
			value: value,
			expiry: now.getTime() + 10 * 60 * 1000, // Convert minutes to milliseconds
		};
		const jsonValue = JSON.stringify(dataWithExpiry);
		localStorage.setItem(key, jsonValue);
	} catch (e) {
		console.error('Error saving data:', e); // Handle saving error
	}
};

export const getData = async (key) => {
	try {
		const jsonValue = localStorage.getItem(key);
		// console.log('jsonValue', jsonValue);
		if (!jsonValue) {
			return null; // Key not found
		}
		const dataWithExpiry = JSON.parse(jsonValue);
		console.log('dataWithExpiry', dataWithExpiry);
		const now = new Date();
		if (now.getTime() > dataWithExpiry.expiry) {
			// Data has expired
			localStorage.removeItem(key);
			// console.log('55', dataWithExpiry);
			return null;
		}
		return dataWithExpiry.value; // Return the value if not expired
	} catch (e) {
		console.error('Error reading data:', e); // Handle read error
		return null;
	}
};

const UiReducer = createSlice({
	name: 'ui',
	initialState: {
		tab: 'men',
		cart: (await getData('cart')) ? await getData('cart') : null,
		modal: false,
		whishlist: (await getData('whishlist')) ? await getData('whishlist') : null,
		productDetail: (await getData('productDetail'))
			? await getData('productDetail')
			: null,
		drawermodal: false,
		drawerNotificationmodal: false,
	},
	reducers: {
		setTab(state, action) {
			state.tab = action.payload;
		},
		toggleModal(state, action) {
			state.modal = action.payload;
		},
		toggleDrawerModal(state, action) {
			state.drawermodal = action.payload;
		},
		toggleNotificationDrawerModal(state, action) {
			state.drawerNotificationmodal = action.payload;
		},
		setProductDetail(state, action) {
			state.productDetail = action.payload;
			storeData('productDetail', action.payload);
		},
		addCartRow(state, action) {
			// Ensure `state.cart` is initialized as an array if it's null or undefined
			if (!state.cart) {
				state.cart = [];
			}

			const itemExists = state.cart.some(
				(item) => item.id === action.payload.id
			);

			if (!itemExists) {
				const itemWithQuantity = {
					...action.payload,
					qnty: action.payload.min_order,
					size: '',
				};
				state.cart.push(itemWithQuantity);

				// Update local storage
				storeData('cart', state.cart);
			}
		},
		increseCartRow(state, action) {
			const item = state.cart.find((item) => item.id === action.payload.id);

			if (item && item?.qnty < item.max_order) {
				// If the item exists, increase its quantity
				item.qnty += 1;
				storeData('cart', state.cart);
			}
		},
		decreaseCartRow(state, action) {
			// console.log("id", action.payload.id);
			const item = state.cart.find((item) => item.id === action.payload.id);

			if (item && item?.qnty > item?.min_order) {
				// If the item exists, increase its quantity
				item.qnty -= 1;
				storeData('cart', state.cart);
			}
		},
		updateCartSize(state, action) {
			console.log('id', action);
			const item = state.cart.find((item) => item.id === action.payload.id);
			item.size = action.payload.size;
			storeData('cart', state.cart);
		},
		clearCartRow(state, action) {
			state.cart = [];
			storeData('cart', state.cart);
		},
		removeSelectedCart(state, action) {
			state.cart = state.cart.filter((row) => row.id !== action.payload.id); // assuming 'id' is unique
			storeData('cart', state.cart);
		},
		addWhitelistRow(state, action) {
			if (!state.whishlist) {
				state.whishlist = [];
			}
			const itemExists = state.whishlist.some(
				(item) => item.id === action.payload.id
			); // assuming each item has a unique 'id'

			if (!itemExists) {
				state.whishlist.push(action.payload);
				storeData('whishlist', state.whishlist);
			}
		},
		removeSelectedWhish(state, action) {
			state.whishlist = state.whishlist.filter(
				(row) => row.id !== action.payload.id
			); // assuming 'id' is unique
			storeData('whishlist', state.whishlist);
		},
		clearWhislistRow(state, action) {
			state.whishlist = [];
			storeData('whishlist', state.whishlist);
		},
	},
});

const { actions } = UiReducer;

export const {
	setTab,
	toggleNotificationDrawerModal,
	addCartRow,
	clearCartRow,
	removeSelectedCart,
	addWhitelistRow,
	clearWhislistRow,
	removeSelectedWhish,
	increseCartRow,
	decreaseCartRow,
	updateCartSize,
	toggleModal,
	setProductDetail,
	toggleDrawerModal,
} = actions;

export default UiReducer;
