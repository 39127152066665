import React from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';

const BankDetails = () => {
	return (
		<>
			<Header />
			<Footer />
		</>
	);
};

export default BankDetails;
