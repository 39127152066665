// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { useSelector } from 'react-redux';
import { updateTokenDispatch } from '../reducers/HomeReducer';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyAYI9pWY3T7RYY1tJKvl7PuOOdLxGOmi-g',
	authDomain: 'zero7shirts-b233b.firebaseapp.com',
	projectId: 'zero7shirts-b233b',
	storageBucket: 'zero7shirts-b233b.firebasestorage.app',
	messagingSenderId: '602410601590',
	appId: '1:602410601590:web:e55cd51f88ed14849c6357',
	measurementId: 'G-N58Q3X23BB',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const generateToken = async (loginInfo, dispatch) => {
	const permission = await Notification.requestPermission();
	console.log(permission);
	if (permission === 'granted') {
		const token = await getToken(messaging, {
			vapidKey:
				'BAQfVO9B7Z_IQvO60KJf0plH7hSqI2chl5BIKsPwmohrq9Fcn_0rYm0NTAiYfeDtcJVpWFM_JU_B-mdFjn8ymAA',
		});
		console.log('token', token);
		let form = {
			token: token,
		};
		dispatch(updateTokenDispatch(form, loginInfo?.result?.id));
	}
};
