import React, { useEffect, useState } from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { addWhitelistRow, addCartRow } from '../reducers/UiReducer';
import { Box, Slider, TextField, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import {
	categoryDispatch,
	customeridDispatch,
	markupDispatch,
	notificationDispatch,
	productDispatch,
	productPremiumDispatch,
	showProductCategoryDispatch,
} from '../reducers/HomeReducer';
import PriceRangeSlider from '../components/resuableComponents/PriceRangeSlider';
import ProductCard from '../components/resuableComponents/ProductCard';

const ProductList = () => {
	const {
		product,
		category,
		productPremium,
		loginInfo,
		markup,
		productCategory,
	} = useSelector((state) => state.home);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [value, setValue] = useState([200, 800]);
	const [toggle, setToggle] = useState(false);
	const [filteredProducts, setFilteredProducts] = useState([]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		filterProducts(newValue);
	};
	// Filter products by price range
	const filterProducts = (range) => {
		const filtered = product?.result?.filter(
			(product) =>
				product.price_per >= range[0] && product.price_per <= range[1]
		);
		setFilteredProducts(filtered);
		setToggle(true);
	};

	console.log('filteredProducts', filteredProducts);
	useEffect(() => {
		window.scrollTo(0, 0);
		if (location?.state) {
			dispatch(showProductCategoryDispatch(location?.state?.id));
		}
		if (loginInfo?.result?.role === 'Premium') {
			dispatch(productPremiumDispatch());
		}
		dispatch(productDispatch());
		dispatch(markupDispatch());
		dispatch(categoryDispatch());
		dispatch(customeridDispatch(loginInfo?.result?.id));
		dispatch(notificationDispatch(loginInfo?.result?.id));
	}, []);
	let data = [];
	if (product?.result?.length > 0 && productPremium?.result?.length > 0) {
		data = [...productPremium?.result, ...product?.result];
	}
	console.log('data', product);
	return (
		<>
			<Header />
			<main class='main'>
				<div class='page-header breadcrumb-wrap'>
					<div class='container'>
						<div class='breadcrumb'>
							<a href='index.html' rel='nofollow'>
								Home
							</a>
							<span></span> Shop
						</div>
					</div>
				</div>
				<section class='mt-50 mb-50'>
					<div class='container'>
						<div class='row flex-row-reverse'>
							<div class='col-lg-9'>
								<div class='shop-product-fillter'>
									<div class='totall-product'>
										<p>
											{' '}
											We found{' '}
											<strong class='text-brand'>
												{product?.result?.length}
											</strong>{' '}
											items for you!
										</p>
									</div>
									<div class='sort-by-product-area'>
										{/* <div class='sort-by-cover mr-10'>
											<div class='sort-by-product-wrap'>
												<div class='sort-by'>
													<span>
														<i class='fi-rs-apps'></i>Show:
													</span>
												</div>
												<div class='sort-by-dropdown-wrap'>
													<span>
														{' '}
														50 <i class='fi-rs-angle-small-down'></i>
													</span>
												</div>
											</div>
											<div class='sort-by-dropdown'>
												<ul>
													<li>
														<a class='active' href='#'>
															50
														</a>
													</li>
													<li>
														<a href='#'>100</a>
													</li>
													<li>
														<a href='#'>150</a>
													</li>
													<li>
														<a href='#'>200</a>
													</li>
													<li>
														<a href='#'>All</a>
													</li>
												</ul>
											</div>
										</div> */}
										{/* <div class='sort-by-cover'>
											<div class='sort-by-product-wrap'>
												<div class='sort-by'>
													<span>
														<i class='fi-rs-apps-sort'></i>Sort by:
													</span>
												</div>
												<div class='sort-by-dropdown-wrap'>
													<span>
														{' '}
														Featured <i class='fi-rs-angle-small-down'></i>
													</span>
												</div>
											</div>
											<div class='sort-by-dropdown'>
												<ul>
													<li>
														<a class='active' href='#'>
															Featured
														</a>
													</li>
													<li>
														<a href='#'>Price: Low to High</a>
													</li>
													<li>
														<a href='#'>Price: High to Low</a>
													</li>
													<li>
														<a href='#'>Release Date</a>
													</li>
													<li>
														<a href='#'>Avg. Rating</a>
													</li>
												</ul>
											</div>
										</div> */}
									</div>
								</div>
								<div class='row product-grid-3'>
									{toggle ? (
										filteredProducts?.length > 0 ? (
											filteredProducts?.map((i) => {
												return (
													<ProductCard
														i={i}
														loginInfo={loginInfo}
														markup={markup}
													/>
												);
											})
										) : (
											<p>No Product Found</p>
										)
									) : data?.length > 0 ? (
										data?.map((i) => {
											return (
												<ProductCard
													i={i}
													loginInfo={loginInfo}
													markup={markup}
												/>
											);
										})
									) : (
										product?.result?.map((i) => {
											return (
												<ProductCard
													i={i}
													loginInfo={loginInfo}
													markup={markup}
												/>
											);
										})
									)}
								</div>
								{/* <!--pagination--> */}
								{/* <div class='pagination-area mt-15 mb-sm-5 mb-lg-0'>
									<nav aria-label='Page navigation example'>
										<ul class='pagination justify-content-start'>
											<li class='page-item active'>
												<a class='page-link' href='#'>
													01
												</a>
											</li>
											<li class='page-item'>
												<a class='page-link' href='#'>
													02
												</a>
											</li>
											<li class='page-item'>
												<a class='page-link' href='#'>
													03
												</a>
											</li>
											<li class='page-item'>
												<a class='page-link dot' href='#'>
													...
												</a>
											</li>
											<li class='page-item'>
												<a class='page-link' href='#'>
													16
												</a>
											</li>
											<li class='page-item'>
												<a class='page-link' href='#'>
													<i class='fi-rs-angle-double-small-right'></i>
												</a>
											</li>
										</ul>
									</nav>
								</div> */}
							</div>
							<div class='col-lg-3 primary-sidebar sticky-sidebar'>
								<div class='widget-category mb-30'>
									<h5 class='section-title style-1 mb-30 wow fadeIn animated'>
										Category
									</h5>
									<ul class='categories'>
										{category?.result?.map((i) => {
											return (
												<li
													onClick={() =>
														dispatch(showProductCategoryDispatch(i.id))
													}>
													<a>{i.name}</a>
												</li>
											);
										})}
									</ul>
								</div>
								{/* <!-- Fillter By Price --> */}
								<div class='sidebar-widget price_range range mb-30'>
									<div class='widget-header position-relative mb-20 pb-10'>
										<h5 class='widget-title mb-10'>Fill by price</h5>
										<div class='bt-1 border-color-1'></div>
									</div>
									<Box
										sx={{
											// width: 300,
											margin: '20px auto',
											padding: '10px',
											border: '1px solid #ddd',
											borderRadius: '8px',
											backgroundColor: '#f9f9f9',
										}}>
										<Typography variant='h6' gutterBottom>
											Price Range
										</Typography>
										<Slider
											value={value}
											onChange={handleChange}
											valueLabelDisplay='auto'
											min={0}
											max={1000}
											sx={{ marginBottom: 2 }}
										/>
										<Box display='flex' alignItems='center'>
											<Typography variant='body1' sx={{ marginRight: 1 }}>
												Range:
											</Typography>
											<TextField
												variant='outlined'
												size='small'
												value={`₹${value[0]} - ₹${value[1]}`}
												InputProps={{ readOnly: true }}
												sx={{ width: 150 }}
											/>
										</Box>
									</Box>
									{/* <a href='shop-grid-right.html' class='btn btn-sm btn-default'>
										<i class='fi-rs-filter mr-5'></i> Fillter
									</a> */}
								</div>
								{/* <!-- Product sidebar Widget --> */}
								{/* <div class='sidebar-widget product-sidebar  mb-30 p-30 bg-grey border-radius-10'>
									<div class='widget-header position-relative mb-20 pb-10'>
										<h5 class='widget-title mb-10'>New products</h5>
										<div class='bt-1 border-color-1'></div>
									</div>
									<div class='single-post clearfix'>
										<div class='image'>
											<img src='assets/imgs/shop/thumbnail-3.jpg' alt='#' />
										</div>
										<div class='content pt-10'>
											<h5>
												<a href='shop-product-detail.html'>Chen Cardigan</a>
											</h5>
											<p class='price mb-0 mt-5'>$99.50</p>
											<div class='product-rate'>
												<div
													class='product-rating'
													style={{ width: '90%' }}></div>
											</div>
										</div>
									</div>
									<div class='single-post clearfix'>
										<div class='image'>
											<img src='assets/imgs/shop/thumbnail-4.jpg' alt='#' />
										</div>
										<div class='content pt-10'>
											<h6>
												<a href='shop-product-detail.html'>Chen Sweater</a>
											</h6>
											<p class='price mb-0 mt-5'>$89.50</p>
											<div class='product-rate'>
												<div
													class='product-rating'
													style={{ width: '80%' }}></div>
											</div>
										</div>
									</div>
									<div class='single-post clearfix'>
										<div class='image'>
											<img src='assets/imgs/shop/thumbnail-5.jpg' alt='#' />
										</div>
										<div class='content pt-10'>
											<h6>
												<a href='shop-product-detail.html'>Colorful Jacket</a>
											</h6>
											<p class='price mb-0 mt-5'>$25</p>
											<div class='product-rate'>
												<div
													class='product-rating'
													style={{ width: '60%' }}></div>
											</div>
										</div>
									</div>
								</div> */}
								<div class='banner-img wow fadeIn mb-45 animated d-lg-block d-none'>
									<img src='assets/imgs/banner/banner-11.jpg' alt='' />
									<div class='banner-text'>
										<span>Women Zone</span>
										<h4>
											Save 17% on <br />
											Office Dress
										</h4>
										<a href='shop-grid-right.html'>
											Shop Now <i class='fi-rs-arrow-right'></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<Footer />
		</>
	);
};

export default ProductList;
