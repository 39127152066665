import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import Header from '../components/resuableComponents/Header';
// import { Skeleton, Image, Divider } from 'antd';
// import { Popover } from 'antd';
import {
	Container,
	Box,
	Avatar,
	Button,
	useTheme,
	useMediaQuery,
	Grid,
	Divider,
} from '@mui/material';
import logo from '../assets/images/logo.webp';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../components/resuableComponents/Footer';
import { otpVerifyDispatch } from '../reducers/HomeReducer';
import Swal from 'sweetalert2';

function useQuery() {
	const { search } = useLocation();
	console.log('Search', search);
	return React.useMemo(() => new URLSearchParams(search), [search]);
}
const VerifyUser = () => {
	let query = useQuery();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { aiimage, loading, airecipe, desc, des } = useSelector(
		(state) => state.home
	);
	console.log(query.get('validationToken'));
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { userLogin } = useSelector((state) => state.home);
	const String = query.get('validationToken');
	useEffect(() => {
		if (query.get('validationToken')) {
			let form = {
				otp: query.get('validationToken'),
			};
			dispatch(otpVerifyDispatch(form, navigate, Swal));
			// let formData = {
			// 	content: query.get('validationString'),
			// };
		}
	}, [query.get('validationString')]);
	return (
		<>
			<Header />
			<Container
				style={{
					marginBottom: 20,
					// margin: 0,
					height: '100vh',
					// display: 'flex',
					// justifyContent: 'center',
					alignItems: 'center',
					// background: 'linear-gradient(135deg, #ff9a9e, #fad0c4)',
				}}>
				{/* <p>Recipe / {String}</p> */}
				<Grid container spacing={4}>
					<Grid item xs={12} lg={12}>
						{loading ? (
							<div>
								<div
									className='loader-container'
									style={{
										textAlign: 'center',
										marginBottom: 100,
										marginTop: 100,
									}}>
									<div className='loader'>
										<div className='circle'></div>
										<div className='circle'></div>
										<div className='circle'></div>
										<div className='circle'></div>
									</div>
									<br />
									<p style={{ textAlign: 'center', fontSize: 36 }}>
										Please wait verify your email.....
									</p>
									<br />
									<CircularProgress />
								</div>
							</div>
						) : (
							''
						)}
					</Grid>
				</Grid>
				<Divider />
			</Container>
			<Footer />
		</>
	);
};

export default VerifyUser;
