import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { passwordResetDispatch } from '../reducers/HomeReducer';

const ResetPasswordForm = ({ token }) => {
	const dispatch = useDispatch();
	// Validation Schema
	const resetPasswordValidationSchema = Yup.object({
		password: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
			.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
			.matches(/[0-9]/, 'Password must contain at least one number')
			.matches(
				/[@$!%*?&]/,
				'Password must contain at least one special character'
			)
			.required('Password is required'),
		password_confirmation: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
			.required('Password confirmation is required'),
	});

	// Initial Values
	const resetPasswordInitialValues = {
		password: '',
		password_confirmation: '',
	};

	// Form Submission Handler
	const handleResetPasswordSubmit = (values) => {
		let form = {
			token: token,
			...values,
		};
		dispatch(passwordResetDispatch(form, Swal));
	};

	return (
		<Formik
			initialValues={resetPasswordInitialValues}
			validationSchema={resetPasswordValidationSchema}
			onSubmit={handleResetPasswordSubmit}>
			{({ isSubmitting }) => (
				<Form style={{ marginTop: 100 }}>
					<div className='form-group'>
						<Field
							type='password'
							name='password'
							placeholder='New Password'
							className='form-control'
						/>
						<ErrorMessage
							name='password'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='password'
							name='password_confirmation'
							placeholder='Confirm New Password'
							className='form-control'
						/>
						<ErrorMessage
							name='password_confirmation'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<button
							type='submit'
							className='btn btn-fill-out btn-block hover-up'
							disabled={isSubmitting}>
							Reset Password
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ResetPasswordForm;
