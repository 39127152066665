import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { userRegistrationDispatch } from '../reducers/HomeReducer';
import Swal from 'sweetalert2';

const RegistrationForm = () => {
	const dispatch = useDispatch();

	// Updated Validation schema using Yup
	const validationSchema = Yup.object({
		firstname: Yup.string().required('First name is required'),
		lastname: Yup.string().required('Last name is required'),
		gstInNumber: Yup.string().required('GST In Number is required'),
		panNumber: Yup.string().required('Pan Number is required'),
		contact: Yup.string()
			.matches(/^\d{10}$/, 'Contact must be a 10-digit number')
			.required('Contact is required'),
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
		password: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
			.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
			.matches(/[0-9]/, 'Password must contain at least one number')
			.matches(
				/[@$!%*?&]/,
				'Password must contain at least one special character'
			)
			.required('Password is required'),
		confirm_password: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
			.required('Confirm password is required'),
		terms: Yup.boolean().oneOf([true], 'You must accept the terms and policy'),
		firmName: Yup.string().required('Firm Name is required'),
		city: Yup.string().required('City is required'),
		state: Yup.string().required('State is required'),
		pincode: Yup.string()
			.matches(/^\d{6}$/, 'Pincode must be a 6-digit number')
			.required('Pincode is required'),
		address: Yup.string().required('Address is required'),
	});

	// Initial values for the form
	const initialValues = {
		firstname: '',
		lastname: '',
		contact: '',
		email: '',
		password: '',
		confirm_password: '',
		gstInNumber: '',
		panNumber: '',
		firmName: '',
		city: '',
		state: '',
		pincode: '',
		address: '',
	};

	// Submit handler
	const onSubmit = (values) => {
		console.log('Form data:', values);
		dispatch(userRegistrationDispatch(values, Swal));
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}>
			{() => (
				<Form method='post'>
					<div className='form-group'>
						<Field
							type='text'
							name='firstname'
							placeholder='First Name'
							className='form-control'
						/>
						<ErrorMessage
							name='firstname'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='text'
							name='lastname'
							placeholder='Last Name'
							className='form-control'
						/>
						<ErrorMessage
							name='lastname'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='text'
							name='contact'
							placeholder='Contact'
							className='form-control'
						/>
						<ErrorMessage
							name='contact'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='text'
							name='email'
							placeholder='Email'
							className='form-control'
						/>
						<ErrorMessage
							name='email'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='text'
							name='gstInNumber'
							placeholder='GST In Number'
							className='form-control'
						/>
						<ErrorMessage
							name='gstInNumber'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='text'
							name='panNumber'
							placeholder='Pan Number'
							className='form-control'
						/>
						<ErrorMessage
							name='panNumber'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='text'
							name='firmName'
							placeholder='Firm Name'
							className='form-control'
						/>
						<ErrorMessage
							name='firmName'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='text'
							name='city'
							placeholder='City'
							className='form-control'
						/>
						<ErrorMessage
							name='city'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='text'
							name='state'
							placeholder='State'
							className='form-control'
						/>
						<ErrorMessage
							name='state'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='text'
							name='pincode'
							placeholder='Pincode'
							className='form-control'
						/>
						<ErrorMessage
							name='pincode'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							as='textarea'
							name='address'
							placeholder='Address'
							className='form-control'
						/>
						<ErrorMessage
							name='address'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='password'
							name='password'
							placeholder='Password'
							className='form-control'
						/>
						<ErrorMessage
							name='password'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<Field
							type='password'
							name='confirm_password'
							placeholder='Confirm Password'
							className='form-control'
						/>
						<ErrorMessage
							name='confirm_password'
							component='div'
							className='error-message'
						/>
					</div>

					<div className='form-group'>
						<button
							type='submit'
							className='btn btn-fill-out btn-block hover-up'
							name='login'>
							Submit &amp; Register
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default RegistrationForm;
