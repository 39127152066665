import React, { useEffect, useRef } from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	customeridDispatch,
	notificationDispatch,
} from '../reducers/HomeReducer';

const OrderPlaced = () => {
	const animationContainer = useRef(null);
	const { order, loginInfo } = useSelector((state) => state.home);
	const dispatch = useDispatch();

	useEffect(() => {
		if (window.lottie) {
			window.lottie.loadAnimation({
				container: animationContainer.current,
				renderer: 'svg', // Use 'svg' renderer for better quality
				loop: true, // Loop the animation
				autoplay: true, // Start animation immediately
				path: 'https://example.com/path/to/diwali-firecracker.json', // URL to your Lottie JSON file
			});
		}
		dispatch(notificationDispatch(loginInfo?.result?.id));
		dispatch(customeridDispatch(loginInfo?.result?.id));
	}, []);

	return (
		<>
			<Header />
			<div className='order-success'>
				<div className='success-message'>
					<h2>OrderID: {order?.orderId}</h2>
					<br />
					<h2>Order Placed Successfully!</h2>
					<p>
						Your order has been successfully placed. Thank you for shopping with
						us!
					</p>
					<Link to='/dashboard'>
						<button className='btn'>Go to Dashboard</button>
					</Link>
				</div>

				{/* Diwali Firecracker Animation */}
				<div
					className='firecracker-animation'
					ref={animationContainer}
					style={{
						width: '300px',
						height: '300px',
						marginTop: '20px',
					}}></div>

				<style jsx>{`
					.order-success {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						height: 100vh;
						background-color: #fefefe;
					}
					.success-message {
						text-align: center;
					}
					.btn {
						margin-top: 20px;
						padding: 10px 20px;
						font-size: 16px;
						background-color: #f39c12;
						border: none;
						border-radius: 5px;
						color: white;
						cursor: pointer;
					}
					.btn:hover {
						background-color: #e67e22;
					}
					.firecracker-animation {
						margin-top: 30px;
						width: 300px;
						height: 300px;
					}
				`}</style>
			</div>
			<Footer />
		</>
	);
};

export default OrderPlaced;
