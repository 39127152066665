import { axiosInstance } from '../Axios';
import { createSlice } from '@reduxjs/toolkit';
import { clearCartRow, getData, storeData } from './UiReducer';

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		loginInfo: (await getData('loginInfo')) ? await getData('loginInfo') : null,
		user: null,
		forgot: null,
		otp: null,
		reset: null,
		changepassword: null,
		lead: null,
		category: null,
		product: null,
		payment: null,
		updateCustomer: null,
		order: null,
		orderProduct: null,
		orderAll: null,
		fetchpayment: null,
		customerDetail: (await getData('customerDetail'))
			? await getData('customerDetail')
			: null,
		banner: null,
		token: null,
		productPremium: null,
		markup: null,
		bank: null,
		custTrans: null,
		pquantity: null,
		productCategory: null,
		sendReset: null,
		passwordReset: null,
		features: null,
		ads: null,
		bottom: null,
		single: null,
		productAjax: null,
		productDetail: null,
		notify: null,
		addAdminnotify: null,
		notifydelete: null,
		fetchpayment: null,
	},
	reducers: {
		loginRequest(state, action) {
			state.loading = true;
		},
		loginSuccess(state, action) {
			state.loading = false;
			state.loginInfo = action.payload;
			storeData('loginInfo', action.payload);
			state.error = null;
		},
		loginFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		userRegistrationRequest(state, action) {
			state.loading = true;
		},
		userRegistrationSuccess(state, action) {
			state.loading = false;
			state.user = action.payload;
			state.error = null;
		},
		userRegistrationFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		forgotRequest(state, action) {
			state.loading = true;
		},
		forgotSuccess(state, action) {
			state.loading = false;
			state.forgot = action.payload;
			state.error = null;
		},
		forgotFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		otpRequest(state, action) {
			state.loading = true;
		},
		otpSuccess(state, action) {
			state.loading = false;
			state.otp = action.payload;
			state.error = null;
		},
		otpFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		resetRequest(state, action) {
			state.loading = true;
		},
		resetSuccess(state, action) {
			state.loading = false;
			state.reset = action.payload;
			state.error = null;
		},
		resetFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		changepasswordRequest(state, action) {
			state.loading = true;
		},
		changepasswordSuccess(state, action) {
			state.loading = false;
			state.changepassword = action.payload;
			state.error = null;
		},
		changepasswordFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		leadRequest(state, action) {
			state.loading = true;
		},
		leadSuccess(state, action) {
			state.loading = false;
			state.lead = action.payload;
			state.error = null;
		},
		leadFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		categoryRequest(state, action) {
			state.loading = true;
		},
		categorySuccess(state, action) {
			state.loading = false;
			state.category = action.payload;
			state.error = null;
		},
		categoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productRequest(state, action) {
			state.loading = true;
		},
		productSuccess(state, action) {
			state.loading = false;
			state.product = action.payload;
			state.error = null;
		},
		productFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		paymentRequest(state, action) {
			state.loading = true;
		},
		paymentSuccess(state, action) {
			state.loading = false;
			state.payment = action.payload;
			state.error = null;
		},
		paymentFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateCustomerRequest(state, action) {
			state.loading = true;
		},
		updateCustomerSuccess(state, action) {
			state.loading = false;
			state.updateCustomer = action.payload;
			state.error = null;
		},
		updateCustomerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		orderRequest(state, action) {
			state.loading = true;
		},
		orderSuccess(state, action) {
			state.loading = false;
			state.order = action.payload;
			state.error = null;
		},
		orderFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		orderProductRequest(state, action) {
			state.loading = true;
		},
		orderProductSuccess(state, action) {
			state.loading = false;
			state.orderProduct = action.payload;
			state.error = null;
		},
		orderProductFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		orderAllRequest(state, action) {
			state.loading = true;
		},
		orderAllSuccess(state, action) {
			state.loading = false;
			state.orderAll = action.payload;
			state.error = null;
		},
		orderAllFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		fetchpaymentRequest(state, action) {
			state.loading = true;
		},
		fetchpaymentSuccess(state, action) {
			state.loading = false;
			state.fetchpayment = action.payload;
			state.error = null;
		},
		fetchpaymentFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		customeridRequest(state, action) {
			state.loading = true;
		},
		customeridSuccess(state, action) {
			state.loading = false;
			state.customerDetail = action.payload;
			storeData('customerDetail', action.payload);
			state.error = null;
		},
		customeridFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bannerRequest(state, action) {
			state.loading = true;
		},
		bannerSuccess(state, action) {
			state.loading = false;
			state.banner = action.payload;
			state.error = null;
		},
		bannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateTokenRequest(state, action) {
			state.loading = true;
		},
		updateTokenSuccess(state, action) {
			state.loading = false;
			state.token = action.payload;
			state.error = null;
		},
		updateTokenFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productPremiumRequest(state, action) {
			state.loading = true;
		},
		productPremiumSuccess(state, action) {
			state.loading = false;
			state.productPremium = action.payload;
			state.error = null;
		},
		productPremiumFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		markupRequest(state, action) {
			state.loading = true;
		},
		markupSuccess(state, action) {
			state.loading = false;
			state.markup = action.payload;
			state.error = null;
		},
		markupFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bankRequest(state, action) {
			state.loading = true;
		},
		bankSuccess(state, action) {
			state.loading = false;
			state.bank = action.payload;
			state.error = null;
		},
		bankFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		custRequest(state, action) {
			state.loading = true;
		},
		custSuccess(state, action) {
			state.loading = false;
			state.custTrans = action.payload;
			state.error = null;
		},
		custFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		pquantityRequest(state, action) {
			state.loading = true;
		},
		pquantitySuccess(state, action) {
			state.loading = false;
			state.pquantity = action.payload;
			state.error = null;
		},
		pquantityFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productCategoryRequest(state, action) {
			state.loading = true;
		},
		productCategorySuccess(state, action) {
			state.loading = false;
			state.productCategory = action.payload;
			state.error = null;
		},
		productCategoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		sendResetRequest(state, action) {
			state.loading = true;
		},
		sendResetSuccess(state, action) {
			state.loading = false;
			state.sendReset = action.payload;
			state.error = null;
		},
		sendResetFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		passwordResetRequest(state, action) {
			state.loading = true;
		},
		passwordResetSuccess(state, action) {
			state.loading = false;
			state.passwordReset = action.payload;
			state.error = null;
		},
		passwordResetFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		featuresRequest(state, action) {
			state.loading = true;
		},
		featuresSuccess(state, action) {
			state.loading = false;
			state.features = action.payload;
			state.error = null;
		},
		featuresFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adsRequest(state, action) {
			state.loading = true;
		},
		adsSuccess(state, action) {
			state.loading = false;
			state.ads = action.payload;
			state.error = null;
		},
		adsFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bottomRequest(state, action) {
			state.loading = true;
		},
		bottomSuccess(state, action) {
			state.loading = false;
			state.bottom = action.payload;
			state.error = null;
		},
		bottomFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		singleRequest(state, action) {
			state.loading = true;
		},
		singleSuccess(state, action) {
			state.loading = false;
			state.single = action.payload;
			state.error = null;
		},
		singleFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productAjaxRequest(state, action) {
			state.loading = true;
		},
		productAjaxSuccess(state, action) {
			state.loading = false;
			state.productAjax = action.payload;
			state.error = null;
		},
		productAjaxFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productDetailRequest(state, action) {
			state.loading = true;
		},
		productDetailSuccess(state, action) {
			state.loading = false;
			state.productDetail = action.payload;
			// storeData('productDetail', action.payload);
			state.error = null;
		},
		productDetailFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		notifyRequest(state, action) {
			state.loading = true;
		},
		notifySuccess(state, action) {
			state.loading = false;
			state.notify = action.payload;
			state.error = null;
		},
		notifyFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addAdminnotifyRequest(state, action) {
			state.loading = true;
		},
		addAdminnotifySuccess(state, action) {
			state.loading = false;
			state.addAdminnotify = action.payload;
			state.error = null;
		},
		addAdminnotifyFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		notifydeleteRequest(state, action) {
			state.loading = true;
		},
		notifydeleteSuccess(state, action) {
			state.loading = false;
			state.notifydelete = action.payload;
			state.error = null;
		},
		notifydeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		loggedRequest(state, action) {
			state.loading = true;
		},
		loggedSuccess(state, action) {
			state.loading = false;
			state.loginInfo = action.payload;
			storeData('loginInfo', action.payload);
			state.error = null;
		},
		loggedFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		'Content-Type': 'application/json',
	},
};
const config2 = {
	headers: {
		'Content-Type': 'multipart/form-data',
	},
};
export const {
	loginRequest,
	loginSuccess,
	loginFail,
	userRegistrationRequest,
	userRegistrationSuccess,
	userRegistrationFail,
	forgotRequest,
	forgotSuccess,
	forgotFail,
	otpRequest,
	otpSuccess,
	otpFail,
	resetRequest,
	resetSuccess,
	resetFail,
	changepasswordRequest,
	changepasswordSuccess,
	changepasswordFail,
	leadRequest,
	leadSuccess,
	leadFail,
	categoryRequest,
	categorySuccess,
	categoryFail,
	productRequest,
	productSuccess,
	productFail,
	paymentRequest,
	paymentSuccess,
	paymentFail,
	updateCustomerRequest,
	updateCustomerSuccess,
	updateCustomerFail,
	orderRequest,
	orderSuccess,
	orderFail,
	orderProductRequest,
	orderProductSuccess,
	orderProductFail,
	orderAllRequest,
	orderAllSuccess,
	orderAllFail,
	fetchpaymentRequest,
	fetchpaymentSuccess,
	fetchpaymentFail,
	customeridRequest,
	customeridSuccess,
	customeridFail,
	bannerRequest,
	bannerSuccess,
	bannerFail,
	updateTokenRequest,
	updateTokenSuccess,
	updateTokenFail,
	productPremiumRequest,
	productPremiumSuccess,
	productPremiumFail,
	markupRequest,
	markupSuccess,
	markupFail,
	bankRequest,
	bankSuccess,
	bankFail,
	custRequest,
	custSuccess,
	custFail,
	pquantityRequest,
	pquantitySuccess,
	pquantityFail,
	productCategoryRequest,
	productCategorySuccess,
	productCategoryFail,
	sendResetRequest,
	sendResetSuccess,
	sendResetFail,
	passwordResetRequest,
	passwordResetSuccess,
	passwordResetFail,
	featuresRequest,
	featuresSuccess,
	featuresFail,
	adsRequest,
	adsSuccess,
	adsFail,
	bottomRequest,
	bottomSuccess,
	bottomFail,
	singleRequest,
	singleSuccess,
	singleFail,
	productAjaxRequest,
	productAjaxSuccess,
	productAjaxFail,
	productDetailRequest,
	productDetailSuccess,
	productDetailFail,
	notifyRequest,
	notifySuccess,
	notifyFail,
	addAdminnotifyRequest,
	addAdminnotifySuccess,
	addAdminnotifyFail,
	notifydeleteRequest,
	notifydeleteSuccess,
	notifydeleteFail,
	loggedRequest,
	loggedSuccess,
	loggedFail,
} = actions;

export const loginDispatch = (bodyData, navigate, Swal) => async (dispatch) => {
	try {
		dispatch(loginRequest());
		const { data } = await axiosInstance.post(
			'/customer/login',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(loginSuccess(data));
		if (data.status === 'success') {
			dispatch(customeridDispatch(data?.result?.id));
			navigate('/dashboard');
			Swal.fire({
				title: data?.message,
				icon: 'success',
				draggable: true,
			});
		} else if (data.status === 'failed') {
			Swal.fire({
				title: 'Error!',
				text: data.message || 'An unexpected error occurred.',
				icon: 'error',
				confirmButtonText: 'OK',
			});
		}
	} catch (error) {
		dispatch(
			loginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const loggedDispatch = (bodyData) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(loggedRequest());
		const { data } = await axiosInstance.post(
			'/customer/logged_user',
			bodyData,
			config
		);
		console.log('data', data);

		if (data?.result) {
			dispatch(loggedSuccess(data));
		} else {
			localStorage.removeItem('loginInfo');
			localStorage.removeItem('customerDetail');
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			loggedFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const userRegistrationDispatch =
	(bodyData, Swal) => async (dispatch) => {
		try {
			dispatch(userRegistrationRequest());
			const { data } = await axiosInstance.post(
				'/customer/register',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(userRegistrationSuccess(data));
			if (data.status === 200) {
				Swal.fire({
					title: 'Accout Created Successfully',
					text: 'Kindly check email',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			if (error.response && error.response.data) {
				const errors = error.response.data.errors || {}; // Default to an empty object if undefined
				const errorMessages = Object.values(errors).flat(); // Flatten nested arrays
				Swal.fire({
					title: 'Error!',
					text: errorMessages,
					icon: 'error',
					confirmButtonText: 'OK',
				});
			} else {
				Swal.fire({
					title: 'Error!',
					text: error.message || 'An unexpected error occurred.',
					icon: 'error',
					confirmButtonText: 'OK',
				});
				// Handle other types of errors (e.g., network issues, timeouts)
				console.error(
					'Error:',
					error.message || 'An unexpected error occurred.'
				);
			}
			dispatch(
				userRegistrationFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const forgotPasswordDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(forgotRequest());
		const { data } = await axiosInstance.post('/forgot', bodyData, config);
		console.log('data', data);
		dispatch(forgotSuccess(data));
	} catch (error) {
		dispatch(
			forgotFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
// export const OtpVerifyDispatch = (bodyData) => async (dispatch) => {
// 	try {
// 		dispatch(otpRequest());
// 		const { data } = await axiosInstance.post(
// 			'/otp_verification',
// 			bodyData,
// 			config
// 		);
// 		console.log('data', data);
// 		dispatch(otpSuccess(data));
// 	} catch (error) {
// 		dispatch(
// 			otpFail(
// 				error.response && error.response.data.message
// 					? error.response.data.message
// 					: error.message
// 			)
// 		);
// 	}
// };
export const resetPasswordDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(resetRequest());
		const { data } = await axiosInstance.post(
			'/reset_password',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(resetSuccess(data));
	} catch (error) {
		dispatch(
			resetFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const changePasswordDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(changepasswordRequest());
		const { data } = await axiosInstance.post(
			'/change_password',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(changepasswordSuccess(data));
	} catch (error) {
		dispatch(
			changepasswordFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addLeadDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		dispatch(leadRequest());
		const { data } = await axiosInstance.post('/addlead', bodyData, config);
		console.log('data', data);
		dispatch(leadSuccess(data));
		if (data?.status === 'success') {
			alert('!! Thank you for Interest Our Team will Contact Shortly !!');
			navigate('/');
		} else {
			alert('Failed');
		}
	} catch (error) {
		alert('Failed');
		dispatch(
			leadFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const categoryDispatch = () => async (dispatch) => {
	try {
		dispatch(categoryRequest());
		const { data } = await axiosInstance.get('/categories', config);
		console.log('data', data);
		dispatch(categorySuccess(data));
	} catch (error) {
		alert('Failed');
		dispatch(
			categoryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const productDispatch = () => async (dispatch) => {
	try {
		dispatch(productRequest());
		const { data } = await axiosInstance.get('/product', config);
		console.log('data', data);
		dispatch(productSuccess(data));
	} catch (error) {
		dispatch(
			productFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addpaymentDispatch =
	(bodyData, notify, Swal) => async (dispatch) => {
		try {
			dispatch(paymentRequest());
			const { data } = await axiosInstance.post('/payment', bodyData, config2);
			console.log('data', data);
			dispatch(paymentSuccess(data));
			if (data?.status === 'success') {
				Swal.fire({
					title: '!! Your Request has been Successfully submitted!!',
					icon: 'success',
					draggable: true,
				});
				dispatch(addAdminnotificationDispatch(notify));
				dispatch(showPaymentDispatch());
				// alert('!! Your Request has been Successfully submitted!!');
				// navigate('/');
			}
		} catch (error) {
			if (error.response && error.response.data) {
				const errors = error.response.data.errors || {}; // Default to an empty object if undefined
				const errorMessages = Object.values(errors).flat(); // Flatten nested arrays
				Swal.fire({
					title: 'Error!',
					text: errorMessages,
					icon: 'error',
					confirmButtonText: 'OK',
				});
			} else {
				Swal.fire({
					title: 'Error!',
					text: error.message || 'An unexpected error occurred.',
					icon: 'error',
					confirmButtonText: 'OK',
				});
				// Handle other types of errors (e.g., network issues, timeouts)
				console.error(
					'Error:',
					error.message || 'An unexpected error occurred.'
				);
			}
			dispatch(
				paymentFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const updateCustomerDispatch =
	(bodyData, id, Swal) => async (dispatch) => {
		try {
			dispatch(updateCustomerRequest());
			const { data } = await axiosInstance.post(
				`/customer/update/${id}`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(updateCustomerSuccess(data));
			if (data?.status === 'success') {
				// alert('Customer Update Successfully');
				Swal.fire({
					title: data?.message,
					icon: 'success',
					draggable: true,
				});
				// navigate('/');
			} else {
				alert('Failed');
			}
		} catch (error) {
			alert('Failed');
			dispatch(
				updateCustomerFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const addOrderDispatch =
	(bodyData, cart, navigate, Swal, Pay, paymentOption, id) =>
	async (dispatch) => {
		try {
			dispatch(orderRequest());
			const { data } = await axiosInstance.post('/order', bodyData, config2);
			console.log('data', data);
			dispatch(orderSuccess(data));
			if (data?.status === 'success') {
				Swal.fire({
					title: 'Order Placed Successfully',
					icon: 'success',
					draggable: true,
				});
				let notify = {
					cust_id: id,
					desc: 'Order Placed',
					status: 1,
				};
				dispatch(addAdminnotificationDispatch(notify));
				let formPay = {
					orderId: data?.orderId,
					...Pay,
				};
				if (paymentOption === 'wallet') {
					dispatch(fetchpaymentDispatch(formPay));
				}
				dispatch(clearCartRow());
				cart.forEach((item) => {
					let form = {
						order_id: data?.data.id,
						product_id: item.id,
						quantity: item.qnty,
						price: Number(
							Math.round(
								Number(Number(item.set_quantity) * Number(item.qnty)) *
									Number(item.price_per)
							)
						),
					};
					let quantity = {
						quantity: item.qnty,
					};
					dispatch(productQuantityDispatch(quantity, item.id));
					dispatch(addOrderProductDispatch(form, navigate));
				});
			}
		} catch (error) {
			alert('Failed');
			dispatch(
				orderFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const addOrderProductDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			dispatch(orderProductRequest());
			const { data } = await axiosInstance.post(
				'/orderproducts',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(orderProductSuccess(data));
			if (data) {
				// alert('!! Thank you for Interest Our Team will Contact Shortly !!');
				navigate('/order');
			} else {
				alert('Failed');
			}
		} catch (error) {
			alert('Failed');
			dispatch(
				orderProductFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const orderDispatch = () => async (dispatch) => {
	try {
		dispatch(orderAllRequest());
		const { data } = await axiosInstance.get('/orderAll', config);
		console.log('data', data);
		dispatch(orderAllSuccess(data));
	} catch (error) {
		dispatch(
			orderAllFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchpaymentDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(fetchpaymentRequest());
		const { data } = await axiosInstance.post(
			'/customer/fetchFundsOrder',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(fetchpaymentSuccess(data));
		if (data) {
			dispatch(customeridDispatch(bodyData?.customer_id));
			// alert('!! Your Request has been Successfully submitted!!');
			// navigate('/');
		} else {
			// alert('Failed');
		}
	} catch (error) {
		alert('Failed');
		dispatch(
			fetchpaymentFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const customeridDispatch = (id) => async (dispatch) => {
	try {
		dispatch(customeridRequest());
		const { data } = await axiosInstance.get(`/customer/${id}`, config);
		console.log('data', data);
		dispatch(customeridSuccess(data));
	} catch (error) {
		dispatch(
			customeridFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const bannerDispatch = () => async (dispatch) => {
	try {
		dispatch(bannerRequest());
		const { data } = await axiosInstance.get('/banners', config);
		console.log('data', data);
		dispatch(bannerSuccess(data));
	} catch (error) {
		dispatch(
			bannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const updateTokenDispatch = (bodyData, id) => async (dispatch) => {
	try {
		dispatch(updateTokenRequest());
		const { data } = await axiosInstance.post(
			`/customer/update/${id}`,
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(updateTokenSuccess(data));
		if (data?.status === 'success') {
			// navigate('/');
		} else {
			alert('Failed');
		}
	} catch (error) {
		alert('Failed');
		dispatch(
			updateTokenFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const productPremiumDispatch = () => async (dispatch) => {
	try {
		dispatch(productPremiumRequest());
		const { data } = await axiosInstance.get('/productPremiumList', config);
		console.log('data', data);
		dispatch(productPremiumSuccess(data));
	} catch (error) {
		dispatch(
			productPremiumFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const markupDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(markupRequest());
		const { data } = await axiosInstance.get('/markup', config);
		console.log('data', data);
		dispatch(markupSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			markupFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showBankDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(bankRequest());
		const { data } = await axiosInstance.get('/bankdetails', config);
		console.log('data', data);
		dispatch(bankSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			bankFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showCustTransDispatch = (id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(custRequest());
		const { data } = await axiosInstance.get(`/custtrans/${id}`, config);
		console.log('data', data);
		dispatch(custSuccess(data));
	} catch (error) {
		// alert(error.response.data.message);
		dispatch(
			custFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const productQuantityDispatch = (bodyData, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(pquantityRequest());
		const { data } = await axiosInstance.put(
			`/product/${id}/quantity`,
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(pquantitySuccess(data));
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			pquantityFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const otpVerifyDispatch =
	(bodyData, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			};
			dispatch(otpRequest());
			const { data } = await axiosInstance.post(
				`/customer/verifyOtp`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(otpSuccess(data));
			if (data?.status === 'success') {
				Swal.fire({
					title: data?.message,
					icon: 'success',
					draggable: true,
				});
				navigate('/login');
			}
		} catch (error) {
			// alert();
			Swal.fire({
				title: 'Error!',
				text: error.message || 'An unexpected error occurred.',
				icon: 'error',
				confirmButtonText: 'OK',
			});
			navigate('/');
			dispatch(
				otpFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showProductCategoryDispatch = (id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(productCategoryRequest());
		const { data } = await axiosInstance.get(`/product/${id}`, config);
		console.log('data', data);
		dispatch(productCategorySuccess(data));
	} catch (error) {
		// alert(error.response.data.message);
		dispatch(
			productCategoryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const sendResetLinkDispatch = (bodyData, Swal) => async (dispatch) => {
	try {
		dispatch(sendResetRequest());
		const { data } = await axiosInstance.post(
			'/customer/requestPasswordReset',
			bodyData,
			config2
		);
		console.log('data', data);
		dispatch(sendResetSuccess(data));
		if (data?.status === 'success') {
			Swal.fire({
				title: 'Password Reset Successful',
				text: `A password reset link has been sent to ${bodyData.email}`,
				icon: 'success',
			});
		} else {
			Swal.fire({
				title: 'Error!',
				text: data.message || 'An unexpected error occurred.',
				icon: 'error',
				confirmButtonText: 'OK',
			});
		}
	} catch (error) {
		Swal.fire({
			title: 'Error!',
			text: error.response.message || 'An unexpected error occurred.',
			icon: 'error',
			confirmButtonText: 'OK',
		});
		dispatch(
			sendResetFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const passwordResetDispatch = (bodyData, Swal) => async (dispatch) => {
	try {
		dispatch(passwordResetRequest());
		const { data } = await axiosInstance.post(
			'/customer/resetPassword',
			bodyData,
			config2
		);
		console.log('data', data);
		dispatch(passwordResetSuccess(data));
		if (data?.status === 'success') {
			Swal.fire({
				icon: 'success',
				title: 'Password Reset Successful',
				text: 'Your password has been updated successfully.',
			});
		} else {
			Swal.fire({
				title: 'Error!',
				text: data.message || 'An unexpected error occurred.',
				icon: 'error',
				confirmButtonText: 'OK',
			});
		}
	} catch (error) {
		Swal.fire({
			title: 'Error!',
			text: error.response.message || 'An unexpected error occurred.',
			icon: 'error',
			confirmButtonText: 'OK',
		});
		dispatch(
			passwordResetFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const changePasswordCustomerDispatch =
	(bodyData, Swal) => async (dispatch) => {
		try {
			dispatch(passwordResetRequest());
			const { data } = await axiosInstance.post(
				'/customer/change_password',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(passwordResetSuccess(data));
			if (data?.status === 'success') {
				Swal.fire({
					icon: 'success',
					title: 'Password Changed Successfully',
					text: 'Your password has been updated successfully.',
				});
			} else {
				Swal.fire({
					title: 'Error!',
					text: data.message || 'An unexpected error occurred.',
					icon: 'error',
					confirmButtonText: 'OK',
				});
			}
		} catch (error) {
			Swal.fire({
				title: 'Error!',
				text: error.response.message || 'An unexpected error occurred.',
				icon: 'error',
				confirmButtonText: 'OK',
			});
			dispatch(
				passwordResetFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showfeaturesDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(featuresRequest());
		const { data } = await axiosInstance.get('/features', config);
		console.log('data', data);
		dispatch(featuresSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			featuresFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showAdsDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(adsRequest());
		const { data } = await axiosInstance.get('/ad', config);
		console.log('data', data);
		dispatch(adsSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			adsFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showBottomDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(bottomRequest());
		const { data } = await axiosInstance.get('/bottom', config);
		console.log('data', data);
		dispatch(bottomSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			bottomFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showSingleDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(singleRequest());
		const { data } = await axiosInstance.get('/single', config);
		console.log('data', data);
		dispatch(singleSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			singleFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const productAjaxDispatch = (bodyData) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(productAjaxRequest());
		const { data } = await axiosInstance.post(
			'/productAjaxSearch',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(productAjaxSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			productAjaxFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showProductDetailDispatch = (id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(productDetailRequest());
		const { data } = await axiosInstance.get(`/productdetail/${id}`, config);
		console.log('data', data);
		dispatch(productDetailSuccess(data));
	} catch (error) {
		// alert(error.response.data.message);
		dispatch(
			productDetailFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const notificationDispatch = (id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(notifyRequest());
		const { data } = await axiosInstance.get(`/notification/${id}`, config);
		console.log('data', data);
		dispatch(notifySuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			notifyFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addAdminnotificationDispatch = (bodyData) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(addAdminnotifyRequest());
		const { data } = await axiosInstance.post(
			'/notifications',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(addAdminnotifySuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			addAdminnotifyFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deltenotificationDispatch = (id, cust_id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(notifydeleteRequest());
		const { data } = await axiosInstance.delete(`/notification/${id}`, config);
		console.log('data', data);
		dispatch(notifydeleteSuccess(data));
		if (data) {
			dispatch(notificationDispatch(cust_id));
		}
	} catch (error) {
		// alert(error.response.data.message);
		dispatch(
			notifydeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showPaymentDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(paymentRequest());
		const { data } = await axiosInstance.get('/payment', config);
		console.log('data', data);
		dispatch(paymentSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			paymentFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export default HomeReducer;
