import React, { useEffect, useRef } from 'react';
import {
	Container,
	Typography,
	Card,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Box,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Header from './Header';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import {
	customeridDispatch,
	notificationDispatch,
} from '../../reducers/HomeReducer';

const OrderPage = ({ data }) => {
	const location = useLocation();
	const order = location.state;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { loginInfo } = useSelector((state) => state.home);

	// Ref to capture the content
	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(notificationDispatch(loginInfo?.result?.id));
		dispatch(customeridDispatch(loginInfo?.result?.id));
	}, []);
	const pdfRef = useRef();
	function numberToWords(num) {
		const units = [
			'',
			'One',
			'Two',
			'Three',
			'Four',
			'Five',
			'Six',
			'Seven',
			'Eight',
			'Nine',
		];
		const teens = [
			'',
			'Eleven',
			'Twelve',
			'Thirteen',
			'Fourteen',
			'Fifteen',
			'Sixteen',
			'Seventeen',
			'Eighteen',
			'Nineteen',
		];
		const tens = [
			'',
			'Ten',
			'Twenty',
			'Thirty',
			'Forty',
			'Fifty',
			'Sixty',
			'Seventy',
			'Eighty',
			'Ninety',
		];
		const thousands = ['', 'Thousand', 'Lakh', 'Crore'];

		function getWords(n) {
			let str = '';
			if (n > 99) {
				str += units[Math.floor(n / 100)] + ' Hundred ';
				n %= 100;
			}
			if (n > 10 && n < 20) {
				str += teens[n - 10] + ' ';
			} else {
				str += tens[Math.floor(n / 10)] + ' ';
				str += units[n % 10] + ' ';
			}
			return str.trim();
		}

		if (num === 0) return 'Zero';

		let word = '';
		let i = 0;
		while (num > 0) {
			let part = num % 1000;
			if (part > 0) {
				word = getWords(part) + ' ' + thousands[i] + ' ' + word;
			}
			num = Math.floor(num / 1000);
			i++;
		}

		return word.trim() + ' Rupees Only';
	}
	// Function to generate and download PDF
	const downloadPDF = () => {
		const input = pdfRef.current;
		html2canvas(input, { scale: 2 }).then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF('p', 'mm', 'a4');
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
			pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
			pdf.save(`Invoice_${order.orderId}.pdf`);
		});
	};
	console.log('order', order);
	console.log('cart', JSON?.parse(order?.cart));

	return (
		<>
			<Header />
			<Container style={{ marginTop: 20 }}>
				<Box display={'flex'} justifyContent={'space-between'}>
					<Button
						variant='contained'
						color='primary'
						onClick={() => navigate('/dashboard')}
						style={{ marginBottom: '20px' }}>
						Back To Dashboard
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={downloadPDF}
						style={{ marginBottom: '20px' }}>
						Download PDF
					</Button>
				</Box>
				<div id='invoice-content' ref={pdfRef} style={{ padding: 20 }}>
					<h1 style={{ textAlign: 'center' }}>Tax Invoice</h1>
					<p style={{ textAlign: 'center' }}>INVOICE</p>
					<table
						border='1'
						style={{
							width: '100%',
							borderCollapse: 'collapse',
							fontFamily: 'Arial, sans-serif',
						}}>
						{/* Header Section */}
						<tr>
							<td colSpan='4' style={{ padding: '10px', fontSize: '16px' }}>
								<strong>Zero7Shirts</strong>
								<br />
								BLOCK B 1ST FLOOR B-101 SUMEL-6
								<br />
								JUPITER MILL DUDHESHWAR AHMEDABAD
								<br />
								MOBILE NO: 8591379457
								<br />
								GSTIN/UIN: 24BHXPD2241N1ZB
								<br />
								State Name: Gujarat, Code: 24
							</td>
							<td colSpan='6' style={{ padding: '10px', fontSize: '16px' }}>
								<strong>Invoice No:</strong> {order?.orderId}
								<br />
								<strong>Date:</strong> {order?.updated_at.split('T')[0]}
								<br />
								<strong>e-Way Bill No:</strong>
								<br />
								<strong>Delivery Note:</strong>
								<br />
								<strong>Mode/Terms of Payment:{order?.tran_type}</strong>
								<br />
							</td>
						</tr>

						{/* Consignee and Buyer Details */}
						<tr>
							<td colSpan='4' style={{ padding: '10px' }}>
								<strong>Consignee (Ship to)</strong>
								<br />
								{`${order?.first_name} ${order?.last_name}`}
								<br />
								{order?.address}
								<br />
								PHONE-{order?.userPhone}
								<br />
								State Name: {order?.state} Pin Code: {order?.pincode}
								<br />
								Place of Supply: {order?.state}
							</td>
							<td colSpan='6' style={{ padding: '10px' }}>
								<strong>Buyer (Bill to)</strong>
								<br />
								{`${order?.customer?.firstname} ${order?.customer?.lastname}`}
								<br />
								{order?.customer?.address}
								<br />
								PHONE-{order?.customer?.userPhone}
								<br />
								State Name: {order?.customer?.state} Pin Code:{' '}
								{order?.customer?.pincode}
								<br />
								Place of Supply: {order?.customer?.state}
							</td>
						</tr>

						{/* Dispatch Details */}
						<tr>
							<td colSpan='8' style={{ padding: '10px' }}>
								<strong>Tacking No:</strong> {order?.track_ld}
								<br />
								<strong>Tracking Link:</strong> {order?.track_link}
								<br />
							</td>
						</tr>

						{/* Table Header */}
						<tr style={{ backgroundColor: '#f2f2f2' }}>
							<th style={{ padding: '8px' }}>Sl No</th>
							<th style={{ padding: '8px' }}>Description of Goods</th>
							<th style={{ padding: '8px' }}>GST Rate</th>
							<th style={{ padding: '8px' }}>Quantity</th>
							<th style={{ padding: '8px' }}>Size Preference</th>
							<th style={{ padding: '8px' }}>Rate</th>
							<th style={{ padding: '8px' }}>Amount</th>
							<th style={{ padding: '8px' }}>Total Amount</th>
						</tr>

						{/* Table Rows */}
						{JSON?.parse(order?.cart)?.map((i, index) => {
							return (
								<tr>
									<td style={{ padding: '8px', textAlign: 'center' }}>
										{index + 1}
									</td>
									<td style={{ padding: '8px' }}>{i.name}</td>
									<td style={{ padding: '8px', textAlign: 'center' }}>
										{location?.state?.gst}%
									</td>
									<td style={{ padding: '8px', textAlign: 'center' }}>
										{Number(i.qnty) * Number(i.set_quantity)} PCS
									</td>
									<td style={{ padding: '8px', textAlign: 'center' }}>
										{i.size}
									</td>
									<td style={{ padding: '8px', textAlign: 'center' }}>
										{`₹ ${
											Number(i.price_per) +
											(Number(i.price_per) * Number(parseInt(order.markup))) /
												100
										}`}
									</td>
									<td style={{ padding: '8px', textAlign: 'center' }}>
										{`₹ ${Number(
											Math.round(
												Number(i.qnty) *
													Number(i.set_quantity) *
													Number(i.price_per) +
													Number(
														(Number(i.qnty) *
															Number(i.set_quantity) *
															Number(i.price_per) *
															Number(parseInt(order.markup))) /
															100
													)
											)
										)}`}
									</td>
									<td style={{ padding: '8px', textAlign: 'center' }}>
										{`₹ ${Number(
											Math.round(
												Number(
													Number(
														Math.round(
															Number(i.qnty) *
																Number(i.set_quantity) *
																Number(i.price_per)
														)
													)
												) +
													(Number(
														Number(
															Math.round(
																Number(i.qnty) *
																	Number(i.set_quantity) *
																	Number(i.price_per)
															)
														)
													) *
														Number(order?.markup)) /
														100 +
													(Number(
														Number(
															Math.round(
																Number(i.qnty) *
																	Number(i.set_quantity) *
																	Number(i.price_per) +
																	Number(
																		(Number(i.qnty) *
																			Number(i.set_quantity) *
																			Number(i.price_per) *
																			Number(order?.markup)) /
																			100
																	)
															)
														)
													) *
														Number(location?.state?.gst)) /
														100
											)
										)}`}
									</td>
								</tr>
							);
						})}

						{/* Totals */}
						<tr>
							<td colSpan='7' style={{ padding: '8px', textAlign: 'right' }}>
								<strong>Total</strong>
							</td>
							<td style={{ padding: '8px', textAlign: 'center' }}>
								<strong>
									{`₹ ${Number(
										order?.products.reduce((accumulator, currentValue) => {
											return (
												accumulator +
												Number(
													parseInt(
														Number(
															Number(currentValue.pivot.price) +
																(Number(currentValue.pivot.price) *
																	Number(order?.markup)) /
																	100
														)
													)
												)
											);
										}, 0)
									)}`}
								</strong>
							</td>
						</tr>
						<tr>
							<td colSpan='7' style={{ padding: '8px', textAlign: 'right' }}>
								<strong>GST</strong>
							</td>
							<td style={{ padding: '8px', textAlign: 'center' }}>
								<strong>
									{`₹ ${Number(
										Math.round(
											Number(
												(Number(
													order?.products.reduce(
														(accumulator, currentValue) => {
															return (
																accumulator +
																Number(
																	parseInt(
																		Number(
																			Number(currentValue.pivot.price) +
																				Number(
																					(Number(currentValue.pivot.price) *
																						Number(order?.markup)) /
																						100
																				)
																		)
																	)
																)
															);
														},
														0
													)
												) *
													Number(location?.state?.gst)) /
													100
											)
										)
									)}`}
								</strong>
							</td>
						</tr>
						<tr>
							<td colSpan='7' style={{ padding: '8px', textAlign: 'right' }}>
								<strong>Grand Total</strong>
							</td>
							<td style={{ padding: '8px', textAlign: 'center' }}>
								<strong>
									{`₹ ${
										Number(
											Number(
												order?.products.reduce((accumulator, currentValue) => {
													return (
														accumulator +
														Number(
															parseInt(
																Number(
																	Number(currentValue.pivot.price) +
																		Number(
																			(Number(currentValue.pivot.price) *
																				Number(order?.markup)) /
																				100
																		)
																)
															)
														)
													);
												}, 0)
											)
										) +
										Number(
											Math.round(
												Number(
													(Number(
														order?.products.reduce(
															(accumulator, currentValue) => {
																return (
																	accumulator +
																	Number(
																		parseInt(
																			Number(
																				Number(currentValue.pivot.price) +
																					Number(
																						(Number(currentValue.pivot.price) *
																							Number(order?.markup)) /
																							100
																					)
																			)
																		)
																	)
																);
															},
															0
														)
													) *
														Number(location?.state?.gst)) /
														100
												)
											)
										)
									}`}
								</strong>
							</td>
						</tr>

						{/* Amount in Words */}
						<tr>
							<td colSpan='8' style={{ padding: '8px' }}>
								<strong>Amount Chargeable (in words):</strong> INR{' '}
								{numberToWords(
									Number(
										Number(
											order?.products.reduce((accumulator, currentValue) => {
												return (
													accumulator +
													Number(
														parseInt(
															Number(
																Number(currentValue.pivot.price) +
																	Number(
																		(Number(currentValue.pivot.price) *
																			Number(order?.markup)) /
																			100
																	)
															)
														)
													)
												);
											}, 0)
										)
									) +
										Number(
											Math.round(
												Number(
													(Number(
														order?.products.reduce(
															(accumulator, currentValue) => {
																return (
																	accumulator +
																	Number(
																		parseInt(
																			Number(
																				Number(currentValue.pivot.price) +
																					Number(
																						(Number(currentValue.pivot.price) *
																							Number(order?.markup)) /
																							100
																					)
																			)
																		)
																	)
																);
															},
															0
														)
													) *
														Number(location?.state?.gst)) /
														100
												)
											)
										)
								)}
							</td>
						</tr>

						{/* Bank Details */}
						<tr>
							<td colSpan='8' style={{ padding: '8px' }}>
								<strong>Company's Bank Details</strong>
								<br />
								A/c Holder's Name: OMKAR APPARELS 24-25
								<br />
								Bank Name: ICICI BANK
								<br />
								A/c No: 4933055001003
								<br />
								Branch & IFSC Code: AHMEDABAD-BAPS SHAHIBAUG BRANCH &
								ICIC0004933
								<br />
								SWIFT Code:
							</td>
						</tr>

						{/* Declaration */}
						<tr>
							<td colSpan='8' style={{ padding: '8px' }}>
								<strong>Declaration:</strong> We declare that this invoice shows
								the actual price of the goods described and that all particulars
								are true and correct.
							</td>
						</tr>

						{/* Footer */}
						<tr>
							<td colSpan='8' style={{ padding: '8px', textAlign: 'right' }}>
								For OMKAR APPARELS 24-25
								<br />
								<br />
								Authorized Signatory
							</td>
						</tr>
					</table>
				</div>
				<img src={order?.imgpath} style={{ width: '100%' }} />
			</Container>
			<Footer />
		</>
	);
};

export default OrderPage;
