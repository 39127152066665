import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { addOrderDispatch } from '../reducers/HomeReducer';
import { toggleModal } from '../reducers/UiReducer';
import BasicModal from '../components/resuableComponents/BasicModal';

const ShippingForm = () => {
	const { cart, modal } = useSelector((state) => state.ui);
	const { loginInfo, loading, markup, customerDetail } = useSelector(
		(state) => state.home
	);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues: {
			first_name: loginInfo?.result?.firstname,
			last_name: loginInfo?.result?.lastname,
			address: loginInfo?.result?.address,
			email: loginInfo?.result?.email,
			phone: loginInfo?.result?.contact,
			city: loginInfo?.result?.city,
			state: loginInfo?.result?.state,
			zipcode: loginInfo?.result?.pincode,
			payment_option: '',
			payment_screenshot: null,
		},
		validationSchema: Yup.object({
			first_name: Yup.string().required('First name is required'),
			last_name: Yup.string().required('Last name is required'),
			address: Yup.string().required('Address is required'),
			email: Yup.string()
				.email('Invalid email address')
				.required('Email is required'),
			phone: Yup.number()
				.typeError('Must be a valid phone number')
				.required('Phone is required'),
			city: Yup.string().required('City is required'),
			state: Yup.string().required('State is required'),
			zipcode: Yup.string().required('ZIP code is required'),
			payment_option: Yup.string().required('Select a payment option'),
		}),
		onSubmit: (values) => {
			console.log('Form Submitted:', values);
			const formData = new FormData();
			formData.append('orderId', 'QWERT');
			formData.append('desc', 'Order Product');
			formData.append('bookingStatus', 'pending');
			formData.append(
				'paymentStatus',
				values?.payment_option === 'wallet' ? 'paid' : 'pending'
			);
			formData.append('userEmail', values.email);
			formData.append('userPhone', values?.phone);
			formData.append('first_name', values?.first_name);
			formData.append('last_name', values?.last_name);
			formData.append('address', values?.address);
			formData.append('city', values?.city);
			formData.append('state', values?.state);
			formData.append('pin', values?.zipcode);
			formData.append(
				'baseFare',
				parseFloat(
					Number(
						cart.reduce((accumulator, currentValue) => {
							return (
								accumulator +
								parseFloat(
									Number(
										Math.round(
											Number(
												Math.round(
													Number(
														Number(currentValue.set_quantity) *
															Number(currentValue.qnty)
													) * Number(currentValue.price_per)
												)
											) +
												Number(
													Number(
														Number(
															loginInfo?.result?.role === 'Credit' || 'Premium'
																? Number(
																		Number(currentValue.set_quantity) *
																			Number(currentValue.qnty)
																  ) * Number(currentValue.price_per)
																: 0
														) *
															(Number(
																parseInt(
																	loginInfo?.result?.role === 'Credit'
																		? markup?.result[0]?.markup
																		: markup?.result[0]?.markup_premium
																)
															) /
																100) // Divide by 100 for percentage
													)
												)
										)
									)
								)
							);
						}, 0)
					)
				)
			);
			formData.append('gst', markup?.result[0]?.gst);
			formData.append(
				'markup',
				loginInfo?.result?.role === 'Credit'
					? markup?.result[0]?.markup
					: loginInfo?.result?.role === 'Premium'
					? markup?.result[0]?.markup_premium
					: 0.0
			);
			formData.append('pimage', values?.payment_screenshot);
			formData.append(
				'totalFare',
				parseFloat(
					Number(
						cart.reduce((accumulator, currentValue) => {
							return (
								accumulator +
								parseFloat(
									Number(
										Math.round(
											Number(
												Math.round(
													Number(
														Number(currentValue.set_quantity) *
															Number(currentValue.qnty)
													) * Number(currentValue.price_per)
												)
											) +
												Number(
													Number(
														Number(
															loginInfo?.result?.role === 'Credit' || 'Premium'
																? Number(
																		Number(currentValue.set_quantity) *
																			Number(currentValue.qnty)
																  ) * Number(currentValue.price_per)
																: 0
														) *
															(Number(
																parseInt(
																	loginInfo?.result?.role === 'Credit'
																		? markup?.result[0]?.markup
																		: markup?.result[0]?.markup_premium
																)
															) /
																100) // Divide by 100 for percentage
													)
												)
										)
									)
								)
							);
						}, 0)
					) +
						Number(
							cart.reduce((accumulator, currentValue) => {
								return (
									accumulator +
									parseFloat(
										Number(
											Math.round(
												Number(
													Math.round(
														Number(
															Number(
																Math.round(
																	Number(
																		Math.round(
																			Number(
																				Number(currentValue.set_quantity) *
																					Number(currentValue.qnty)
																			) * Number(currentValue.price_per)
																		)
																	) +
																		Number(
																			Number(
																				Number(
																					loginInfo?.result?.role ===
																						'Credit' || 'Premium'
																						? Number(
																								Number(
																									currentValue.set_quantity
																								) * Number(currentValue.qnty)
																						  ) * Number(currentValue.price_per)
																						: 0
																				) *
																					(Number(
																						parseInt(
																							loginInfo?.result?.role ===
																								'Credit'
																								? markup?.result[0]?.markup
																								: markup?.result[0]
																										?.markup_premium
																						)
																					) /
																						100) // Divide by 100 for percentage
																			)
																		)
																)
															)
														) * Number(parseInt(markup?.result[0]?.gst))
													)
												) / 100
											)
										)
									)
								);
							}, 0)
						)
				)
			);
			formData.append('cust_id', loginInfo?.result?.id);
			formData.append('deliveryDate', '2024-12-12');
			formData.append('cart', JSON.stringify(cart));
			formData.append(
				'tran_type',
				values?.payment_option === 'wallet' ? 'offline' : 'online'
			);
			formData.append('status', '1');
			let pay = {
				customer_id: loginInfo?.result?.id,
				amount: parseFloat(
					Number(
						cart.reduce((accumulator, currentValue) => {
							return (
								accumulator +
								parseFloat(
									Number(
										Math.round(
											Number(
												Math.round(
													Number(
														Number(currentValue.set_quantity) *
															Number(currentValue.qnty)
													) * Number(currentValue.price_per)
												)
											) +
												Number(
													Number(
														Number(
															loginInfo?.result?.role === 'Credit' || 'Premium'
																? Number(
																		Number(currentValue.set_quantity) *
																			Number(currentValue.qnty)
																  ) * Number(currentValue.price_per)
																: 0
														) *
															(Number(
																parseInt(
																	loginInfo?.result?.role === 'Credit'
																		? markup?.result[0]?.markup
																		: markup?.result[0]?.markup_premium
																)
															) /
																100) // Divide by 100 for percentage
													)
												)
										)
									)
								)
							);
						}, 0)
					) +
						Number(
							cart.reduce((accumulator, currentValue) => {
								return (
									accumulator +
									parseFloat(
										Number(
											Math.round(
												Number(
													Math.round(
														Number(
															Number(
																Math.round(
																	Number(
																		Math.round(
																			Number(
																				Number(currentValue.set_quantity) *
																					Number(currentValue.qnty)
																			) * Number(currentValue.price_per)
																		)
																	) +
																		Number(
																			Number(
																				Number(
																					loginInfo?.result?.role ===
																						'Credit' || 'Premium'
																						? Number(
																								Number(
																									currentValue.set_quantity
																								) * Number(currentValue.qnty)
																						  ) * Number(currentValue.price_per)
																						: 0
																				) *
																					(Number(
																						parseInt(
																							loginInfo?.result?.role ===
																								'Credit'
																								? markup?.result[0]?.markup
																								: markup?.result[0]
																										?.markup_premium
																						)
																					) /
																						100) // Divide by 100 for percentage
																			)
																		)
																)
															)
														) * Number(parseInt(markup?.result[0]?.gst))
													)
												) / 100
											)
										)
									)
								);
							}, 0)
						)
				),
			};
			dispatch(
				addOrderDispatch(
					formData,
					cart,
					navigate,
					Swal,
					pay,
					values?.payment_option,
					loginInfo?.result?.id
				)
			);
		},
	});

	return (
		<div className='shipping-form'>
			<div className='mb-25'>
				<h4>Shipping Details</h4>
			</div>
			<form onSubmit={formik.handleSubmit} enableReinitialize={true}>
				{[
					{ name: 'first_name', placeholder: 'First name *' },
					{ name: 'last_name', placeholder: 'Last name *' },
					{ name: 'address', placeholder: 'Address *' },
					{ name: 'email', placeholder: 'Email *', type: 'email' },
					{ name: 'phone', placeholder: 'Phone *', type: 'number' },
					{ name: 'city', placeholder: 'City / Town *' },
					{ name: 'state', placeholder: 'State / County *' },
					{ name: 'zipcode', placeholder: 'Postcode / ZIP *' },
				].map(({ name, placeholder, type = 'text' }) => (
					<div className='form-group' key={name}>
						<input
							type={type}
							name={name}
							value={formik.values[name]}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							placeholder={placeholder}
							className={`form-control ${
								formik.touched[name] && formik.errors[name] ? 'is-invalid' : ''
							}`}
						/>
						{formik.touched[name] && formik.errors[name] && (
							<div className='invalid-feedback'>{formik.errors[name]}</div>
						)}
					</div>
				))}

				{/* Payment Options */}
				<div className='payment_option'>
					{[
						{ value: 'wallet', label: 'Wallet' },
						{ value: 'checkPayment', label: 'Pay Through QR or Bank Details' },
					].map(({ value, label }) => {
						return (
							<div className='custome-radio' key={value}>
								<input
									className='form-check-input'
									type='radio'
									name='payment_option'
									value={value}
									// Disable the wallet radio if wallet balance is less than cart amount
									disabled={
										value === 'wallet' &&
										customerDetail?.result?.wallet <=
											Number(
												cart.reduce((accumulator, currentValue) => {
													return (
														accumulator +
														parseFloat(
															Number(
																Math.round(
																	Number(currentValue.set_quantity) *
																		Number(currentValue.qnty)
																) *
																	Number(currentValue.price_per) +
																	Number(
																		Number(
																			customerDetail?.result?.role ===
																				'Credit' || 'Premium'
																				? currentValue.price_per
																				: 0
																		) *
																			Number(
																				parseInt(
																					loginInfo?.result?.role === 'Credit'
																						? markup?.result[0]?.markup
																						: markup?.result[0]?.markup_premium
																				)
																			)
																	) /
																		100
															)
														)
													);
												}, 0)
											) +
												Number(
													cart.reduce((accumulator, currentValue) => {
														return (
															accumulator +
															parseFloat(
																Number(
																	Math.round(
																		(Number(currentValue.set_quantity) *
																			Number(currentValue.qnty) *
																			Number(
																				Number(currentValue.price_per) *
																					Number(
																						parseInt(markup?.result[0]?.gst)
																					)
																			)) /
																			100
																	)
																)
															)
														);
													}, 0)
												)
									}
									checked={formik.values.payment_option === value}
									// onChange={formik.handleChange}
									onChange={(event) => {
										// Call Formik's handleChange
										formik.handleChange(event);

										// Check if the selected value is 'wallet' and log to console
										if (event.target.value === 'checkPayment') {
											dispatch(toggleModal(true));
										}
									}}
									id={`payment_option_${value}`} // Ensure each radio button has a unique ID
								/>
								<label
									onClick={() => {
										if (value === 'checkPayment') {
											dispatch(toggleModal(true));
										}
									}}
									className='form-check-label'
									htmlFor={`payment_option_${value}`}>
									{label}
								</label>
							</div>
						);
					})}

					{/* File Upload for Check Payment */}
					{formik.values.payment_option === 'checkPayment' && (
						<div className='form-group'>
							<label htmlFor='payment_screenshot'>
								Upload Payment Screenshot
							</label>
							<input
								type='file'
								id='payment_screenshot'
								name='payment_screenshot'
								accept='image/*'
								onChange={(event) => {
									// Store the file in Formik state
									formik.setFieldValue(
										'payment_screenshot',
										event.currentTarget.files[0]
									);
								}}
								className='form-control'
							/>
							{/* Show error if no file is uploaded and checkPayment is selected */}
							{formik.values.payment_option === 'checkPayment' &&
								!formik.values.payment_screenshot && (
									<div
										className='error-message'
										style={{ color: 'red', fontSize: '14px' }}>
										Please upload the payment screenshot.
									</div>
								)}
						</div>
					)}

					{/* Example of form submission or button to proceed */}
					<button
						type='submit'
						className='btn btn-primary'
						disabled={
							formik.values.payment_option === 'checkPayment' &&
							!formik.values.payment_screenshot
						}>
						Proceed with Payment
					</button>
				</div>
			</form>
			<BasicModal />
		</div>
	);
};

export default ShippingForm;
