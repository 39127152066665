import React, { useEffect } from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import {
	customeridDispatch,
	fetchpaymentDispatch,
	loginSuccess,
	notificationDispatch,
	orderDispatch,
	productPremiumDispatch,
	productPremiumSuccess,
	showBankDispatch,
	showCustTransDispatch,
	showPaymentDispatch,
} from '../reducers/HomeReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SecurityDeposit from '../forms/SecurityDeposit';
import UploadDeposit from '../forms/UploadDeposit';
import EditProfile from '../forms/EditProfile';
import { generateToken } from '../notification/firebase';
import { Button } from '@mui/material';
import {
	clearCartRow,
	clearWhislistRow,
	toggleModal,
} from '../reducers/UiReducer';
import BasicModal from '../components/resuableComponents/BasicModal';
import CustomerTranTable from '../components/resuableComponents/CustomerTranTable';
import OrderTable from '../components/resuableComponents/OrderTable';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import ChangePassword from '../forms/ChangePassword';
import PaymentHistory from '../components/resuableComponents/PaymentHistory';

const MyAccount = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loginInfo, loading, orderAll } = useSelector((state) => state.home);
	useEffect(() => {
		if (loginInfo?.token) {
			generateToken(loginInfo, dispatch);
			if (loginInfo?.result?.role === 'Premium') {
				dispatch(productPremiumDispatch());
			}
			dispatch(orderDispatch());
			dispatch(showBankDispatch());
			dispatch(showCustTransDispatch(loginInfo?.result?.id));
			dispatch(customeridDispatch(loginInfo?.result?.id));
			dispatch(notificationDispatch(loginInfo?.result?.id));
			dispatch(showPaymentDispatch());
		} else {
			navigate('/login');
		}
	}, []);

	if (loading) {
		return (
			<div id='preloader-active'>
				<div class='preloader d-flex align-items-center justify-content-center'>
					<div class='preloader-inner position-relative'>
						<div class='text-center'>
							<h5 class='mb-5'>Now Loading</h5>
							<div class='loader'>
								<div class='bar bar1'></div>
								<div class='bar bar2'></div>
								<div class='bar bar3'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<>
			<Header />
			<main class='main'>
				<div class='page-header breadcrumb-wrap'>
					<div class='container'>
						<div class='breadcrumb'>
							<a href='index.html' rel='nofollow'>
								Home
							</a>
							<span></span> Pages
							<span></span> Account
						</div>
					</div>
				</div>
				<section class='pt-50 pb-150'>
					<div class='container'>
						<div class='row'>
							<div class='col-lg-10 m-auto'>
								<div class='row'>
									<div class='col-md-4'>
										<div class='dashboard-menu'>
											<ul class='nav flex-column' role='tablist'>
												<li class='nav-item'>
													<a
														class='nav-link active'
														id='dashboard-tab'
														data-bs-toggle='tab'
														href='#dashboard'
														role='tab'
														aria-controls='dashboard'
														aria-selected='false'>
														<i class='fi-rs-settings-sliders mr-10'></i>
														Dashboard
													</a>
												</li>
												{loginInfo?.result?.status === '1' && (
													<>
														<li class='nav-item'>
															<a
																class='nav-link'
																id='orders-tab'
																data-bs-toggle='tab'
																href='#orders'
																role='tab'
																aria-controls='orders'
																aria-selected='false'>
																<i class='fi-rs-shopping-bag mr-10'></i>Orders
															</a>
														</li>
														{/* <li class='nav-item'>
															<a
																class='nav-link'
																id='track-orders-tab'
																data-bs-toggle='tab'
																href='#track-orders'
																role='tab'
																aria-controls='track-orders'
																aria-selected='false'>
																<i class='fi-rs-shopping-cart-check mr-10'></i>
																Track Your Order
															</a>
														</li> */}
														{/* <li class='nav-item'>
															<a
																class='nav-link'
																id='address-tab'
																data-bs-toggle='tab'
																href='#address'
																role='tab'
																aria-controls='address'
																aria-selected='true'>
																<i class='fi-rs-marker mr-10'></i>My Address
															</a>
														</li> */}
														<li class='nav-item'>
															<a
																class='nav-link'
																id='account-detailac-tab'
																data-bs-toggle='tab'
																href='#account-detailac'
																role='tab'
																aria-controls='account-detailac'
																aria-selected='true'>
																<i class='fi-rs-user mr-10'></i>Transaction
															</a>
														</li>
														<li class='nav-item'>
															<a
																class='nav-link'
																id='account-detailacp-tab'
																data-bs-toggle='tab'
																href='#account-detailacp'
																role='tab'
																aria-controls='account-detailacp'
																aria-selected='true'>
																<i class='fi-rs-user mr-10'></i>Change Password
															</a>
														</li>
														<li class='nav-item'>
															<a
																class='nav-link'
																id='account-detail-tab'
																data-bs-toggle='tab'
																href='#account-detail'
																role='tab'
																aria-controls='account-detail'
																aria-selected='true'>
																<i class='fi-rs-user mr-10'></i>Account details
															</a>
														</li>
														<li class='nav-item'>
															<a
																class='nav-link'
																id='account-detailss-tab'
																data-bs-toggle='tab'
																href='#account-detailss'
																role='tab'
																aria-controls='account-detailss'
																aria-selected='true'>
																<i class='fi-rs-credit-card mr-10'></i>Upload
																Payment Screenshot
															</a>
														</li>
														<li class='nav-item'>
															<a
																class='nav-link'
																id='account-detailacph-tab'
																data-bs-toggle='tab'
																href='#account-detailacph'
																role='tab'
																aria-controls='account-detailacph'
																aria-selected='true'>
																<i class='fi-rs-user mr-10'></i>Payment History
															</a>
														</li>
													</>
												)}
												{loginInfo?.result?.status === '0' && (
													<li class='nav-item'>
														<a
															class='nav-link'
															id='account-details-tab'
															data-bs-toggle='tab'
															href='#account-details'
															role='tab'
															aria-controls='account-details'
															aria-selected='true'>
															<i class='fi-rs-credit-card mr-10'></i>Upload
															Payment Screenshot
														</a>
													</li>
												)}
												<li
													class='nav-item'
													onClick={() => {
														navigate('/');
														localStorage.removeItem('loginInfo');
														localStorage.removeItem('customerDetail');
														dispatch(loginSuccess(null));
														dispatch(productPremiumSuccess(null));
														dispatch(clearCartRow());
														dispatch(clearWhislistRow());
													}}>
													<a class='nav-link'>
														<i class='fi-rs-sign-out mr-10'></i>Logout
													</a>
												</li>
											</ul>
										</div>
									</div>
									<div class='col-md-8'>
										<div class='tab-content dashboard-content'>
											<div
												class='tab-pane fade active show'
												id='dashboard'
												role='tabpanel'
												aria-labelledby='dashboard-tab'>
												<div class='card'>
													<div class='card-header'>
														<h5 class='mb-0'>
															Hello {loginInfo?.result?.firstname}!
															{loginInfo?.result?.status === '1'
																? ''
																: ' Your account is not active'}
														</h5>
													</div>
													<div class='card-body'>
														<p>
															From your account dashboard. you can easily check
															&amp; view your <a href='#'>recent orders</a>,
															manage your{' '}
															<a href='#'>shipping and billing addresses</a> and{' '}
															<a href='#'>
																edit your password and account details.
															</a>
														</p>
													</div>
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='orders'
												role='tabpanel'
												aria-labelledby='orders-tab'>
												<div class='card'>
													<div class='card-header'>
														<h5 class='mb-0'>Your Orders</h5>
													</div>
													<div class='card-body'>
														<OrderTable />
													</div>
												</div>
											</div>

											<div
												class='tab-pane fade'
												id='track-orders'
												role='tabpanel'
												aria-labelledby='track-orders-tab'>
												<div class='card'>
													<div class='card-header'>
														<h5 class='mb-0'>Orders tracking</h5>
													</div>
													<div class='card-body contact-from-area'>
														<p>
															To track your order please enter your OrderID in
															the box below and press "Track" button. This was
															given to you on your receipt and in the
															confirmation email you should have received.
														</p>
														<div class='row'>
															<div class='col-lg-8'>
																<form
																	class='contact-form-style mt-30 mb-50'
																	action='#'
																	method='post'>
																	<div class='input-style mb-20'>
																		<label>Order ID</label>
																		<input
																			name='order-id'
																			placeholder='Found in your order confirmation email'
																			type='text'
																			class='square'
																		/>
																	</div>
																	<button
																		class='submit submit-auto-width'
																		type='submit'>
																		Track
																	</button>
																</form>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='address'
												role='tabpanel'
												aria-labelledby='address-tab'>
												<div class='row'>
													<div class='col-lg-6'>
														<div class='card mb-3 mb-lg-0'>
															<div class='card-header'>
																<h5 class='mb-0'>Billing Address</h5>
															</div>
															<div class='card-body'>
																<address>
																	{loginInfo?.result?.address}
																	<br /> {loginInfo?.result?.city}
																	<br />
																	{loginInfo?.result?.state} <br />{' '}
																	{loginInfo?.result?.pincode}
																</address>
																<p>{loginInfo?.result?.country}</p>
															</div>
														</div>
													</div>
													<div class='col-lg-6'>
														{/* <div class='card'>
															<div class='card-header'>
																<h5 class='mb-0'>Shipping Address</h5>
															</div>
															<div class='card-body'>
																<address>
																	4299 Express Lane
																	<br />
																	Sarasota, <br />
																	FL 34249 USA <br />
																	Phone: 1.941.227.4444
																</address>
																<p>Sarasota</p>
																<a href='#' class='btn-small'>
																	Edit
																</a>
															</div>
														</div> */}
													</div>
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='account-detail'
												role='tabpanel'
												aria-labelledby='account-detail-tab'>
												<div class='card'>
													<div class='card-header'>
														<h5>Account Details</h5>
													</div>
													<div class='card-body'>
														<EditProfile />
													</div>
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='account-details'
												role='tabpanel'
												aria-labelledby='account-details-tab'>
												<div class='card'>
													<div
														class='card-header'
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
														}}>
														<h5>Upload Your Payment Screenshot</h5>
														<Button onClick={() => dispatch(toggleModal(true))}>
															Show Bank Details
														</Button>
													</div>
													<div class='card-body'>
														<SecurityDeposit />
													</div>
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='account-detailac'
												role='tabpanel'
												aria-labelledby='account-detailac-tab'>
												<div class='card'>
													<div
														class='card-header'
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
														}}>
														<h5>Transaction</h5>
														{/* <Button onClick={() => dispatch(toggleModal(true))}>
															Show Bank Details
														</Button> */}
													</div>
													<CustomerTranTable />
													{/* <div class='card-body'>
														<SecurityDeposit />
													</div> */}
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='account-detailacph'
												role='tabpanel'
												aria-labelledby='account-detailacph-tab'>
												<div class='card'>
													<div
														class='card-header'
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
														}}>
														<h5>Payment History</h5>
														{/* <Button onClick={() => dispatch(toggleModal(true))}>
															Show Bank Details
														</Button> */}
													</div>
													<PaymentHistory />
													{/* <div class='card-body'>
														<SecurityDeposit />
													</div> */}
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='account-detailacp'
												role='tabpanel'
												aria-labelledby='account-detailacp-tab'>
												<div class='card'>
													<div
														class='card-header'
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
														}}>
														<h5>Change Password</h5>
														{/* <Button onClick={() => dispatch(toggleModal(true))}>
															Show Bank Details
														</Button> */}
													</div>
													<ChangePassword />
													{/* <div class='card-body'>
														<SecurityDeposit />
													</div> */}
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='account-detailss'
												role='tabpanel'
												aria-labelledby='account-detailss-tab'>
												<div class='card'>
													<div
														class='card-header'
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
														}}>
														<h5>Upload Your Payment Screenshot</h5>
														<Button onClick={() => dispatch(toggleModal(true))}>
															Show Bank Details
														</Button>
													</div>
													<div class='card-body'>
														<UploadDeposit />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<BasicModal />
			<Footer />
		</>
	);
};

export default MyAccount;
