import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addpaymentDispatch } from '../reducers/HomeReducer';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';

const SecurityDeposit = () => {
	const { loginInfo } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const initialValues = {
		amount: '',
		file: null,
		TranId: null,
	};

	const validationSchema = Yup.object({
		amount: Yup.number()
			.required('Amount is required')
			.positive('Amount must be positive')
			.integer('Amount must be an integer'),
		file: Yup.mixed()
			.required('File upload is required')
			.test(
				'fileSize',
				'File size is too large',
				(value) => !value || (value && value.size <= 2 * 1024 * 1024) // 2MB limit
			),
		TranId: Yup.string().required('TranId is required'),
	});

	const handleSubmit = (values) => {
		console.log('Form Data', values);
		const formData = new FormData();
		formData.append('amount', values.amount);
		formData.append('transId', values.TranId);
		formData.append('cust_id', loginInfo?.result?.id);
		formData.append('pimage', values.file);
		formData.append('status', 'pending');
		formData.append('payment_type', 'security');
		let notify = {
			cust_id: loginInfo?.result?.id,
			desc: 'Payment Request',
		};
		dispatch(addpaymentDispatch(formData, notify, Swal));
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}>
			{({ setFieldValue }) => (
				<Form>
					<div className='form-group'>
						<label>Amount *</label>
						<Field name='amount' type='number' className='form-control' />
						<ErrorMessage name='amount' component='div' className='error' />
					</div>
					<div className='form-group'>
						<label>TranId *</label>
						<Field name='TranId' type='text' className='form-control' />
						<ErrorMessage name='TranId' component='div' className='error' />
					</div>

					<div className='form-group'>
						<label>Upload File *</label>
						<input
							name='file'
							type='file'
							className='form-control'
							onChange={(event) =>
								setFieldValue('file', event.currentTarget.files[0])
							}
						/>
						<ErrorMessage name='file' component='div' className='error' />
					</div>

					<button type='submit' className='btn btn-primary'>
						Submit
					</button>
				</Form>
			)}
		</Formik>
	);
};

export default SecurityDeposit;
