import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Dynamically import the Slider component
const Slider = lazy(() => import('react-slick'));

const HeroSlider = () => {
	const { banner } = useSelector((state) => state.home);

	// Slider settings
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 5000,
	};

	return (
		<section
			className='home-slider position-relative pt-50'
			style={{ marginBottom: 50 }}>
			{/* Wrap Slider in Suspense with a fallback loading state */}
			<Suspense fallback={<div>Loading...</div>}>
				<Slider
					{...settings}
					className='hero-slider-1 dot-style-1 dot-style-1-position-1'>
					{banner?.banners?.map((i) => {
						return (
							<div
								className='single-hero-slider single-animation-wrap'
								key={i.id}>
								<div className='container'>
									<div className='row align-items-center slider-animated-1'>
										<div className='col-lg-5 col-md-6'>
											<div className='hero-slider-content-2'>
												<h4 className='animated'>{i.title}</h4>
												<h2 className='animated fw-900'>{i.header}</h2>
												<h1 className='animated fw-900 text-brand'>
													{i.sub_header}
												</h1>
												<p className='animated'>{i.description}</p>
												<a
													className='animated btn btn-brush btn-brush-3'
													href='shop-product-right.html'>
													Shop Now
												</a>
											</div>
										</div>
										<div className='col-lg-7 col-md-6'>
											<div className='single-slider-img single-slider-img-1'>
												<img
													className='animated slider-1-1'
													src={i.imgpath}
													alt='Slider 1'
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</Slider>
			</Suspense>
		</section>
	);
};

export default HeroSlider;
