import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function OrderTable() {
	const { product, loginInfo, customer, orderAll } = useSelector(
		(state) => state.home
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let data = [];
	if (orderAll?.result?.length > 0) {
		orderAll?.result?.map((i) => {
			if (i.cust_id === loginInfo?.result?.id) {
				data.push({
					...i,
				});
			}
		});
	}
	console.log('data', data);
	const columns = [
		{ field: 'id', headerName: 'SNo.', width: 100 },
		{
			field: 'orderId',
			headerName: 'Order ID',
			width: 200,
			renderCell: (cellValues) => {
				return (
					<Box mt={2}>
						<p style={{ fontSize: 14 }}>{`${cellValues.row.orderId}`}</p>
					</Box>
				);
			},
		},
		{
			field: 'bookingStatus',
			headerName: 'Booking Status',
			width: 200,
			renderCell: (cellValues) => {
				return (
					<Box mt={2}>
						<p
							style={{
								fontSize: 14,
							}}>{`${cellValues.row.bookingStatus.toUpperCase()}`}</p>
					</Box>
				);
			},
		},
		{
			field: 'paymentStatus',
			headerName: 'Payment Status',
			width: 200,
			renderCell: (cellValues) => {
				return (
					<Box mt={2}>
						<p
							style={{
								fontSize: 14,
							}}>{`${cellValues.row.paymentStatus.toUpperCase()}`}</p>
					</Box>
				);
			},
		},
		{
			field: 'tran_type',
			headerName: 'Tran Type',
			width: 200,
			renderCell: (cellValues) => {
				return (
					<Box mt={2}>
						<p
							style={{
								fontSize: 14,
							}}>{`${cellValues.row.tran_type.toUpperCase()}`}</p>
					</Box>
				);
			},
		},
		{
			field: 'desc',
			headerName: 'Description',
			width: 300,
			renderCell: (cellValues) => {
				return (
					<Box mt={2}>
						<p style={{}}>{`${cellValues.row.desc}`}</p>
					</Box>
				);
			},
		},
		{
			field: 'Amount',
			headerName: 'Amount',
			width: 200,
			renderCell: (cellValues) => {
				return (
					<Box mt={2}>
						<p style={{ fontSize: 14 }}>{`${cellValues.row.totalFare}`}</p>
					</Box>
				);
			},
		},
		{
			field: 'updated_at',
			headerName: 'Date',
			width: 300,
			renderCell: (cellValues) => {
				return (
					<Box mt={2}>
						<p style={{ fontSize: 14 }}>{`${
							cellValues.row.created_at.split('T')[0].split('-')[2]
						}-${cellValues.row.created_at.split('T')[0].split('-')[1]}-${
							cellValues.row.created_at.split('T')[0].split('-')[0]
						} / ${cellValues.row.created_at.split('T')[1].split('.')[0]}`}</p>
					</Box>
				);
			},
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 300,
			renderCell: (cellValues) => {
				return (
					<Box
						alignItems={'center'}
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}>
						<Button
							variant='contained'
							color='primary'
							onClick={(event) => {
								navigate('/invoice', {
									state: cellValues.row,
								});
							}}>
							View
						</Button>
					</Box>
				);
			},
		},
	];
	const handleClick = (event, cellValues) => {};
	const handleClickDelete = (event, cellValues) => {
		// dispatch(deleteproductDispatch(cellValues.id, loginInfo.token));
	};
	return (
		<Box sx={{ height: 400, width: '100%' }}>
			{data.length > 0 ? (
				<DataGrid
					sx={{
						'&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
							outline: 'none',
						},
						'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
							outline: 'none',
						},
						// Custom scrollbar styles
						'& .MuiDataGrid-virtualScroller': {
							'&::-webkit-scrollbar': {
								height: '8px',
								backgroundColor: '#f5f5f5',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#888',
								borderRadius: '4px',
							},
							'&::-webkit-scrollbar-thumb:hover': {
								backgroundColor: '#555',
							},
						},
					}}
					rows={data}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					disableRowSelectionOnClick
				/>
			) : (
				<h3 style={{ fontSize: 20 }}>No Record Found</h3>
			)}
		</Box>
	);
}
